<template>
  <CompanySearchTable v-if="searchOption === $t('company')"
                      :options-list="optionsList"
                      @selection-changed="emitSelectionChanged" />

  <PersonSearchTable v-else-if="searchOption === $t('person')"
                     :options-list="optionsList"
                     @selection-changed="emitSelectionChanged"/>

  <LeadsOrProjectSearchTable v-else
                             :options-list="optionsList"
                             @selection-changed="emitSelectionChanged"/>

</template>

<script>
import CompanySearchTable
  from "@/components/global-components/searchabel-select-row/searchable-select-row-table/company-search-table/CompanySearchTable";
import LeadsOrProjectSearchTable
  from "@/components/global-components/searchabel-select-row/searchable-select-row-table/leads-or-project-search-table/LeadsOrProjectSearchTable";
import PersonSearchTable
  from "@/components/global-components/searchabel-select-row/searchable-select-row-table/person-search-table/PersonSearchTable";
export default {
  name: "SearchableSelectRowTable",
  components: {PersonSearchTable, LeadsOrProjectSearchTable, CompanySearchTable},
  emits:['selectionChanged'],
  props:{
    optionsList:Array,
    searchOption:String,
  },
  methods:{
    emitSelectionChanged(item){
      this.$emit('selection-changed',item);
    }
  }
}
</script>

<style scoped>

</style>