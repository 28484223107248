<template>
  <div class="mb-4 flex justify-content-end">
      <PrimeButton class="mr-2" icon="pi pi-check" :label="this.$t('confirm')" @click="editReminder" />
      <PrimeButton icon="pi pi-plus" :label="this.$t('add')" @click="addReminder" />
  </div>

  <div class="flex justify-content-between align-items-center mb-4" v-for="(reminder,index) of reminders" :key="reminder">
    <CustomLabel :label="'Text'" :input="reminder.Text">
      <InputText :modelValue="reminder.Text" :class="v$.reminders.$each.$response.$data[index].Text.$error ? 'p-invalid' : ''" @change="setText($event,reminder)"/>
    </CustomLabel>
    <div class="card flex justify-content-center align-items-center">
      <Calendar :modelValue="new Date(Number(reminder.Unix))" showTime hourFormat="24" dateFormat="dd.mm.yy"
                showIcon showButtonBar hideOnDateTimeSelect @update:modelValue="setUnix($event,reminder)"/>
      <PrimeButton class="ml-2" icon="pi pi-trash" severity="danger" rounded outlined  @click="deleteReminder(reminder)" />
      <add-to-calendar-button
          :name="reminder.Text"
          :startDate="new Date(Number(reminder.Unix)).toISOString().split('T')[0]"
          :options="['Apple','Google','Yahoo','iCal']"
          iCalFileName="Reminder-Event"
          trigger="click"
          lightMode="bodyScheme">
      </add-to-calendar-button>

    </div>
  </div>

</template>

<script>
import AddToCalendarButton from 'add-to-calendar-button-vue';
import Uuidv4Generator from "@/mixins/uuidv4/Uuidv4Generator";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import PrimeButton from "primevue/button";
import CustomLabel from "@/components/global-components/custom-label/CustomLabel";
import useVuelidate from "@vuelidate/core";
import {required,helpers} from "@vuelidate/validators";

export default {
  name: "LeadReminder",
  props:{
    leadDataProp:Object,
    remindersProp:Array,
  },
  components:{AddToCalendarButton, CustomLabel, InputText, Calendar, PrimeButton, },
  mixins: [Uuidv4Generator],
  emits: ['closePopup'],
  data(){
    return{
      reminders:JSON.parse(JSON.stringify(this.remindersProp)),
      leadData:JSON.parse(JSON.stringify(this.leadDataProp)),
    }
  },
  /**
   * Don't delete its being used
   * @returns {{v$: *}}
   */
  setup(){
    return{
      v$: useVuelidate(),
    }
  },

  /**
   * Don't delete its being used
   */
  validations(){
    return {
      reminders:{required,
        $each: helpers.forEach({
          Text:{required},
          Unix:{required}
        })
      }
    }
  },
  methods:{
    setUnix(event,reminder){
      // when clicking the clear button, event is null
      if(event){
        reminder.Unix = event.getTime();
      }else{
        reminder.Unix = null;
      }
    },

    setText(event,reminder){
      reminder.Text = event.target.value;
    },

    addReminder(){
      const reminder = {
        Id: this.createUuidv4(),
        Text: null,
        Unix: new Date().getTime()};

      this.reminders.push(reminder);
    },

    editReminder(){
      this.v$.$validate();
      if(!this.v$.$error){
        this.leadData.Reminders = this.reminders;
        this.leadData.LastEdited = new Date().getTime();
        this.$toast.add({severity:'success', summary:this.$t('confirmed'), detail: this.$t('swal.remindersEdited'), life: 3000});
        this.$store.dispatch('editLead',this.leadData).then(() => {
          this.$emit('close-popup',this.leadData);
        });
      }
    },

    deleteReminder(reminder){
      this.$confirm.require({
        header: this.$t('swal.deleteReminderHeader'),
        message: this.$t('swal.deleteReminderText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.reminderDeleted'), life: 3000 });
          this.reminders = this.reminders.filter(foundReminder => foundReminder.Id !== reminder.Id);
          this.leadData.Reminders = this.reminders;
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

  },
}
</script>

<style scoped>

</style>