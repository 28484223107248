<template>
  <TabView>
    <TabPanel :header="$t('open') + ' (' + projects.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'personProjects'"
                     :style="{'height': windowHeight}"
                     :data-prop="projects"
                     :columns="columns"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :show-toggle-column="true"
                     :filter-prop="filter"
                     :filter-options="filterOptions"
                     :sortable="true"
                     :has-removable-sort="true"
                     :key="tableKey"
                     :selection-mode="'single'"
                     @row-selected="goToProject"
                     :show-load-more-data="showLoadMoreProjects"
                     @load-more-data="loadMoreData"
                     :resizable-columns="true"/>
    </TabPanel>
    <TabPanel :header="$t('closed') + ' (' + closedProjects.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'personClosedProjects'"
                     :style="{'height': windowHeight}"
                     :data-prop="closedProjects"
                     :columns="closedColumns"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :show-toggle-column="true"
                     :filter-prop="closedFilter"
                     :filter-options="filterOptions"
                     :sortable="true"
                     :has-removable-sort="true"
                     :key="tableKey"
                     :selection-mode="'single'"
                     @row-selected="goToProject"
                     :show-load-more-data="showLoadMoreClosedProjects"
                     @load-more-data="loadMoreClosedData"
                     :resizable-columns="true"/>
    </TabPanel>
  </TabView>
</template>

<script>
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import {FilterMatchMode} from "primevue/api";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
export default {
  name: "PersonMaterialTableProjectTab",
  components:{MaterialTable, TabView, TabPanel},
  emits:['loadMoreData'],
  data(){
    return{
      columns:[
        { field: 'CreationNumber', header: this.$t('number') },
        { field: 'Title', header: this.$t('title') },
        { field: 'Description', header: this.$t('description') },
        { field: 'Creator', header: this.$t('creator') },
        { field: 'Responsible.Name', header: this.$t('responsible') },
        { field: 'Created', header: this.$t('createdOn') },
        { field: 'Company.Name', header: this.$t('company') },
        { field: 'Status', header: 'Status' },
        { field: 'OpenSince', header: this.$t('openSince') },
        { field: 'Supplier', header: this.$t('supplier')},
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CreationNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Creator: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Responsible.Name': { value: null, matchMode: FilterMatchMode.IN },
        Created: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Company.Name': { value: null, matchMode: FilterMatchMode.IN },
        Status: { value: null, matchMode: FilterMatchMode.CONTAINS },
        OpenSince: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Supplier: { value: null, matchMode: FilterMatchMode.CONTAINS },
      }, 
      closedColumns:[
        { field: 'CreationNumber', header: this.$t('number') },
        { field: 'Title', header: this.$t('title') },
        { field: 'Description', header: this.$t('description') },
        { field: 'Creator', header: this.$t('creator') },
        { field: 'Responsible.Name', header: this.$t('responsible') },
        { field: 'Created', header: this.$t('createdOn') },
        { field: 'Company.Name', header: this.$t('company') },
        { field: 'Supplier', header: this.$t('supplier')},
      ],
      closedFilter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CreationNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Creator: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Responsible.Name': { value: null, matchMode: FilterMatchMode.IN },
        Created: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Company.Name': { value: null, matchMode: FilterMatchMode.IN },
        Supplier: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['CreationNumber', 'Title', 'Description', 'Creator', 'Responsible.Name', 'Company.Name', 'Supplier'],
      tableKey:0,
      windowHeight: '',
    }
  },
  props:{
    projects:Array,
    closedProjects:Array,
    isUser:Boolean,
    showLoadMoreProjects:Object,
    showLoadMoreClosedProjects:Object,
  },
  methods:{
    goToProject(project){
      this.$router.push({
        path:"/projects/dashboard/" + project.Id,
        params:project.Id
      });
    },
    loadMoreClosedData(){
      this.$emit('load-more-data','closedProject');
    },

    loadMoreData(){
      this.$emit('load-more-data','project');
    }
  },
  
  mounted(){
    this.windowHeight = window.innerHeight * 0.52 + 'px';
  }
}
</script>

<style scoped>

</style>