<template>
  <MaterialTable :data-key="'Id'"
                 :table-id="'company'"
                 :style="{'height': windowHeight}"
                 @row-selected="emitSelectionChanged"
                 :data-prop="optionsList"
                 :columns="companyColumns"
                 :has-paginator="true"
                 :rows-count="10"
                 :has-global-filter="true"
                 :filter-prop="companyFilter"
                 :filter-options="companyFilterOptions"
                 :sortable="true"
                 :show-toggle-column="true"
                 :has-removable-sort="true"
                 :key="'company_' + tableKey"
                 :selection-mode="'single'"
                 :resizable-columns="true"/>
</template>

<script>
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import {FilterMatchMode} from "primevue/api";
export default {
  name: "CompanySearchTable",
  components: {MaterialTable},
  emits:['selectionChanged'],
  props:{
    optionsList:Array,
  },
  data(){
    return{
      companyColumns:[
        { field: 'Name', header: this.$t('name') },
        { field: 'CompanyNumber', header: this.$t('companyNumber') },
        { field: 'Address', header: this.$t('address') },
        { field: 'City', header: this.$t('location') },
      ],
      companyFilter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CompanyNumber: { value: null, matchMode: FilterMatchMode.CONTAINS},
        Address: { value: null, matchMode: FilterMatchMode.CONTAINS },
        City: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      companyFilterOptions:['Name', 'CompanyNumber', 'Address', 'City'],
      tableKey:0,
      windowHeight: '',
    }
  },
  methods:{
    emitSelectionChanged(item){
      this.$emit('selection-changed',item);
    }
  },

  mounted(){
    this.windowHeight = window.innerHeight * 0.6 + 'px';
  }
}
</script>

<style scoped>

</style>