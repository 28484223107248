<template>
  <div style="min-height: 600px">
    <div class="col-lg-12 control-section">
      <div class="content-wrapper">
        <ejs-grid id="Grid"
                  ref="grid"
                  :dataSource="gridDataSource"
                  width="100%"
                  :allowPaging="true"
                  :allowSelection="true"
                  :toolbar='toolbarOptions'
                  :allowExcelExport='true'
                  :toolbarClick='toolbarClick'
                  :allowSorting="true"
                  :allowFiltering="true"
                  :filterSettings="filterOptions"
                  :pageSettings="pageSettings"
                  @excelQueryCellInfo="onExcelQueryCellInfo"
                  @recordClick="goToProjectDashboard" >
          <e-columns>
            <e-column field="Title" :headerText="$t('title')" type="string"/>
            <e-column field="ProjectId" :headerText="$t('project')" type="string"/>
            <e-column field="CommissionNumber" :headerText="$t('commissionNumber')" type="string" />
            <e-column field="CommissionValue" :headerText="$t('commissionValue')" type="string" />
            <e-column field="Contact" :headerText="$t('contact')" type="string" />
            <e-column field="Responsible" :headerText="$t('responsible')" type="string"/>
            <e-column field="OrderNumber" :headerText="$t('orderNumber')" type="string" />
            <e-column field="OrderValue" :headerText="$t('orderValue')" type="string" />
            <e-column field="ReferenceNumber" :headerText="$t('referenceNumber')" type="string" />
            <e-column field="SalesDate" :headerText="$t('salesDate')" type="date" format="dd.MM.yyyy"/>
            <e-column field="DeliveryDate" :headerText="$t('deliveryDate')" type="date" format="dd.MM.yyyy"/>
            <e-column field="Equipment" :headerText="$t('equipments')" type="string"/>
            <e-column field="OfferName" :headerText="$t('offer')" type="string"/>
            <e-column field="OfferValue" :headerText="$t('offerValue')" type="string"/>
            <e-column field="Supplier" :headerText="$t('supplier')" type="string"/>
            <e-column field="CompetitionModel" :headerText="$t('competitionModel')" type="string"/>
            <e-column field="MachineModel" :headerText="$t('machineModel')" type="string"/>
            <e-column field="Discount" :headerText="$t('discountPercentage')" type="string"/>
          </e-columns>
        </ejs-grid>
      </div>
    </div>
  </div>
</template>

<script>
import {ExcelExport, Filter, GridComponent, Page, Selection, Sort, Toolbar} from "@syncfusion/ej2-vue-grids";
import {ColumnDirective, ColumnsDirective} from "@syncfusion/ej2-vue-querybuilder";
import QueryBuilderFunctions from "@/mixins/query-builder-functions/queryBuilderFunctions";

export default {
  name: "SalesQueryTable",
  mixins:[QueryBuilderFunctions],
  components:{
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective,
    'ejs-grid': GridComponent,
  },
  data(){
    return{
      filterOptions:{type:'Excel'},
      gridDataSource: [],
      pageSettings: { pageSize: 20, pageCount: 5 },
      importRules: {},
    }
  },

  computed:{
    toolbarOptions(){
      const options = ['ExcelExport'];

      if(this.lastEvaluatedKey){
        options.push(
          { text: 'Load all data', tooltipText: 'Load all data', prefixIcon: 'e-refresh', id: 'toolbar_load_all', align: 'Right'},
          { text: 'Load more data', tooltipText: 'Load more data', prefixIcon: 'e-refresh', id: 'toolbar_load', align: 'Right' });
      }
      return options;
    },

    lastEvaluatedKey(){
      return this.$store.getters.salesLastEvaluatedKey;
    }
  },

  methods:{
    prepareDataSource(sales){
      let src = [];

      for (let sale of sales){
        let obj = {};
        obj["Title"] = sale["Title"];
        obj["CommissionNumber"] = sale["CommissionNumber"];
        obj["CommissionValue"] = sale["CommissionValue"];
        obj["OrderNumber"] = sale["OrderNumber"];
        obj["OrderValue"] = sale["OrderValue"];
        obj["ReferenceNumber"] = sale["ReferenceNumber"];
        obj['OfferName'] = sale['OfferName'];
        obj["OfferValue"] = sale["OfferValue"];
        obj['ProjectId'] = sale['ProjectId'];
        obj['Supplier'] = sale['Supplier'];
        obj['CompetitionModel'] = sale['CompetitionModel'];
        obj['MachineModel'] = sale['MachineModel'];
        obj['Discount'] = sale['Discount'];

        // the table cannot display nested values so we modify / prepare the data.
        obj["Company"] = this.getValFromObject(sale.Company, "Name") + " " + this.getValFromObject(sale.Company, "CompanyNumber");
        obj["Responsible"] = this.getValFromObject(sale.Responsible, "Name");
        obj["Contact"] = this.getValFromObject(sale.Contact, "Name");
        obj["ProjectId"] =  this.getProjectTitle(sale.ProjectId);
        obj["Equipment"] =  this.getEquipmentNames(sale.Equipment);

        obj["SalesDate"] = new Date(parseInt(sale.SalesDate));
        obj["DeliveryDate"] = new Date(parseInt(sale.DeliveryDate));

        src.push(obj);
      }
      this.gridDataSource =  [...this.gridDataSource, ...src];
    },

    getProjectTitle(projectId){
      for(let project of this.$store.getters.projects){
        if(project.Id === projectId){
          return project.Title;
        }
      }
      return "-";
    },

    getEquipmentNames(equipments){
      const equipmentNames = [];
      for(let equipment of equipments){
        equipmentNames.push(equipment.Name);
      }

      return equipmentNames.toString();
    },

    getMoreSales(){
      return new Promise((resolve) => {
        if(this.lastEvaluatedKey && this.lastEvaluatedKey.SalesDate && this.lastEvaluatedKey.Id){
          const id = Number(this.lastEvaluatedKey["Id"]);
          const salesDate = Number(this.lastEvaluatedKey["SalesDate"]);
          this.$store.dispatch("getRequest","getMoreSales&query=" +  [id, salesDate]).then(resp => {
            if (resp && resp.statusCode === 200) {
              const body = JSON.parse(resp.body);
              this.$store.commit('setSales', body.Items);
              this.prepareDataSource(body.Items);

              if (body.LastEvaluatedKey && body.Items.length > 0) {
                this.$store.commit('setSalesLastEvaluatedKey', body.LastEvaluatedKey);
              }else{
                this.$store.commit('setSalesLastEvaluatedKey', null);
              }

              resolve();
            }
          });
        }else{
          this.$toast.add({ severity: 'error', summary: this.$t('Info'), detail: this.$t('swal.allDataReceived'), life: 5000 });
        }
      });
     
    },

    getAllSales(){
      this.getMoreSales().then(()=>{
        if(this.lastEvaluatedKey) {
          this.getAllSales();
        }
      });
    },

    toolbarClick(args) {
      if (args.item.id === 'Grid_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        let excelExportProperties = {
          fileName: this.$t('sales') + '_' + new Date().toLocaleDateString() + '_' + ".xlsx",
        };
        this.$refs.grid.excelExport(excelExportProperties);
      }
      
      if (args.item.id === 'toolbar_load') {
        this.getMoreSales();
      }

      if(args.item.id === 'toolbar_load_all'){
        this.getAllSales();
      }
    },

    parseDateString(dateString) {
      const [day, month, year] = dateString.split('.');
      return new Date(year, month - 1, day);
    },

    /**
     * Changed the unix into a date for the Excel export.
     * @param args
     */
    onExcelQueryCellInfo(args) {
      if (args.column.field === 'DeliveryDate' || args.column.field === 'SalesDate') {
        const date = new Date(args.value);
        args.value = date.toLocaleDateString();
      }
    },
    
    goToProjectDashboard(args){
      const projectId = args.rowData.ProjectId;
      this.$router.push({
        path:"/projects/dashboard/" + projectId,
        params:projectId
      });
    }
  },

  mounted(){
    this.prepareDataSource(this.$store.getters.sales);
  },

  provide: {
    grid: [Page, Selection, Sort, Toolbar, ExcelExport, Filter]
  }
}
</script>

<style scoped>

</style>