<template>
  <div class="mb-2 mt-2">
    <CustomLabel v-if="correspondenceType === $t('company')"
                 class="w-full"
                 :label="this.$t('company')"
                 :input="company ? company.Name : null">
      <Dropdown class="w-full"
                v-model="company"
                :class="v$.company.$error ? 'p-invalid' : ''"
                :options="companyOptions"
                optionLabel="Name"
                filter
                :placeholder="this.$t('placeholder.company')"
                autoFilterFocus
                @update:modelValue="setCompany"
                @filter="searchForCompanies"/>
    </CustomLabel>

    <CustomLabel v-else-if="correspondenceType === $t('person')"
                 class="w-full"
                 :label="this.$t('person')"
                 :input="person ? person.FriendlyName : null">
      <Dropdown class="w-full"
                v-model="person"
                :class="v$.person.$error ? 'p-invalid' : ''"
                :options="personOptions"
                optionLabel="FriendlyName"
                filter
                :placeholder="this.$t('placeholder.person')"
                autoFilterFocus />
    </CustomLabel>

    <CustomLabel v-else
                class="w-full"
                :label="this.$t('contact')"
                :input="contact ? contact.ContactName : null">
      <Dropdown class="w-full"
                v-model="contact"
                :class="v$.contact.$error ? 'p-invalid' : ''"
                :options="contactOptions"
                optionLabel="ContactName"
                filter
                :placeholder="this.$t('placeholder.selectContact')"
                autoFilterFocus
                @update:modelValue="setContact"
                @filter="searchForContacts">
        <template #option="slotProps">
          <div class="flex align-items-center">
            <div>{{ slotProps.option.ContactName + ' (' + slotProps.option.CompanyName + ')' }}</div>
          </div>
        </template>
      </Dropdown>
    </CustomLabel>
  </div>

  <div class="mb-2 mt-2">
    <CustomLabel class="w-full"
                :label="this.$t('assignment')"
                :input="correspondence.stage">
      <Dropdown class="w-full"
                v-model="correspondence.stage"
                :options="stageOptions"
                filter
                :placeholder="this.$t('placeholder.assignment')"
                autoFilterFocus />
    </CustomLabel>
  </div>

  <div class="mb-2 mt-2">
    <CustomLabel>
      <Editor v-model="correspondence.text"
              editorStyle="height:300px"
              :class="v$.correspondence.text.$error ? 'p-invalid' : ''" />
    </CustomLabel>
  </div>

  <div v-if="company.Id || person.User || contact.ContactId" class="mb-2 mt-2">
    <FileUpload ref="fileUpload"
                name="demo[]"
                :customUpload="true"
                @uploader="uploadFiles"
                :multiple="true"
                accept=".jpg,.jpeg,.png,.eml,.msg,.doc,.docx,.pdf,.ppt,.pptx,.txt,.xls,.xlsx"
                :maxFileSize="52000000">
      <template #empty>
        <p>Drag and drop files to here to upload.</p>
      </template>
    </FileUpload>
  </div>

  <div v-if="files.length > 0">
    <AttachmentMaterialTable :attachments="files"
                             :show-delete-button="true"
                             @delete-attachment="deleteFile" />
  </div>

</template>

<script>
import Editor from "primevue/editor";
import Dropdown from "primevue/dropdown";
import FileUpload from "primevue/fileupload";
import CustomLabel from "@/components/global-components/custom-label/CustomLabel";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import AttachmentMaterialTable from "@/components/global-components/attachment-material-table/AttachmentMaterialTable";
import globalComputedProperties from "@/mixins/global-computed-properties/global-computed-properties";
import S3FileManager from "@/mixins/s3-file-manager/s3-file-manager";

export default {
  name: "AddCorrespondence",
  components:{AttachmentMaterialTable, Editor, Dropdown, FileUpload, CustomLabel},
  mixins:[globalComputedProperties, S3FileManager],
  emits: ['closePopup'],
  props:{
    correspondenceType:String,
  },
  data(){
    return {
      company:{},
      person:{},
      contact:{},
      correspondence:{
        stage:null,
        sender:null,
        text:null,
        file:[],
        timestamp:new Date().getTime(),
      },
      companyOptions:[],
      contactOptions:[],
      files:[],
      emailContent:null,
    }
  },

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  /**
   * Depending on our correspondence type, we change the validations.
   * @returns {{correspondence: {text: {required: ValidationRuleWithoutParams}}}}
   */
  validations(){
    const validations = {correspondence: {text:{required}}};
    if(this.correspondenceType === this.$t('company')){
      validations.company = {required};
    } else if(this.correspondenceType === this.$t('person')){
      validations.person = {required};
    } else {
      validations.contact = {required};
    }
    return validations;
  },

  computed:{
    personOptions(){
      return this.$store.getters.responsibles;
    },

    stageOptions(){
      const foundTagContainer = this.$store.getters.allTagContainers.find(tagContainer => tagContainer.Coverage === "ticket_stages");
      let stageOptions = [];
      if(foundTagContainer){
        for(let tag of foundTagContainer.Tags){
          const foundCustomList = this.$store.getters.allCustomLists.find(customList => customList.Id === tag);
          if(foundCustomList){
            stageOptions = [...stageOptions, ...foundCustomList.TagNames];
          }
        }
      }

      return stageOptions
    }
  },

  methods:{
    searchForCompanies(event){
      this.$store.commit('setLoading',false);

      clearTimeout(this.searchForCompanies.timeoutId);

      if (event.value.length > 2) {
        this.searchForCompanies.timeoutId = setTimeout(() => {
          this.$store.commit('setLoading',true);
          this.companyOptions = [];
          this.$store.dispatch("getRequest","getCompaniesBySearchValue&query=" + event.value).then(resp => {
            this.$store.commit('setLoading',false);
            if(resp && resp.statusCode === 200){
              this.companyOptions = JSON.parse(resp.body);
            }
          });
        }, 1000);
      }
    },

    setCompany(event){
      this.company = event;
      const foundCompanyIndex = this.companyOptions.findIndex(filteredCompany => filteredCompany.Id === event.Id);
      if(foundCompanyIndex !== -1){
        this.companyOptions[foundCompanyIndex] = event;
      }else{
        this.companyOptions.push(event);
      }
    },

    searchForContacts(event){
      this.$store.commit('setLoading',false);

      clearTimeout(this.searchForContacts.timeoutId);

      if (event.value.length > 2) {
        this.searchForContacts.timeoutId = setTimeout(() => {
          this.$store.commit('setLoading',true);
          this.contactOptions = [];
          this.$store.dispatch("getRequest","getContactsBySearchValue&query=" + event.value).then(resp => {
            this.$store.commit('setLoading',false);
            if(resp && resp.statusCode === 200){
              this.contactOptions = JSON.parse(resp.body);
            }
          });
        }, 1000);
      }
    },

    setContact(event){
      this.contact = event;
    },

    uploadFiles(event){
      for (let file of event.files){
        let fileName = this.getFileName(file.name);
        let filePath = this.getFilePath(file);
        this.$store.commit('setLoading',true);
        // Check if it's an email file
        if (file.type.includes('message')) {
          // Wait for email content extraction asynchronously
          this.getEmailText(file).then(() => {
            fileName += ' ' + `${this.$t('from')}: ${this.emailContent.from} ${this.$t('to')}: ${this.emailContent.to} ${this.$t('date')}: ${this.emailContent.date}`;
            
            this.s3UploadFile(file, filePath).then(() => {
              this.files.push({
                Name:fileName,
                Type:this.getFileType(file.name),
                LastModified:new Date(file.lastModified).toLocaleString(),
                Size:(file.size / (1024*1024)).toFixed(2) + " MB",
                "Key": filePath,
              });

              this.correspondence.file.push({
                name: fileName,
                path: filePath,
              });

              this.$store.commit('setLoading',false);
            }).catch((err) => {
              console.log(err);
              this.$store.commit('setLoading',false);
            });
          });
        }else{
          this.s3UploadFile(file, filePath).then(() => {
            this.files.push({
              Name:fileName,
              Type:this.getFileType(file.name),
              LastModified:new Date(file.lastModified).toLocaleString(),
              Size:(file.size / (1024*1024)).toFixed(2) + " MB",
              "Key": filePath,
            });

            this.correspondence.file.push({
              name: fileName,
              path: filePath,
            });

            this.$store.commit('setLoading',false);
          }).catch((err) => {
            console.log(err);
            this.$store.commit('setLoading',false);
          });
        }
      }
    },

    getEmailText(file){
      return new Promise((resolve) => {
        const reader = new FileReader();
        
        // Read the file as text or binary, depending on your email file format (.eml or .msg)
        reader.onload = (e) => {
          const emailContent = e.target.result;
          this.emailContent = this.parseEmailContent(emailContent);
          resolve();
        };
        
        // Read as text (suitable for .eml files)
        reader.readAsText(file);
      });
    },

    parseEmailContent(emailContent) {
      // Parsing .eml content (simple approach for text-based emails)
      const emailLines = emailContent.split("\n");

      let from = "", to = "", subject = "", date = "";

      emailLines.forEach(line => {
        if (line.startsWith("From:")) {
          from = line.replace("From:", "").trim();
        } else if (line.startsWith("To:")) {
          to = line.replace("To:", "").trim();
        } else if (line.startsWith("Subject:")) {
          subject = line.replace("Subject:", "").trim();
        } else if (line.startsWith("Date:")) {
          date = line.replace("Date:", "").trim();
        }
      });

      return {
        from,
        to,
        subject,
        date,
      }
    },

    /**
     * Returns the filePath depending on our
     * correspondenceType & if a stage is selected.
     * @param file
     * @returns {string}
     */
    getFilePath(file){
      let filePath = "";
      if(this.correspondence.stage) {
        if (this.correspondenceType === this.$t('company')) {
          filePath = this.company.Id + '/' + this.correspondence.stage + '/' + file.name.trim();
        } else if (this.correspondenceType === this.$t('person')) {
          filePath = this.person.User + '/' + this.correspondence.stage + '/' + file.name.trim();
        } else if (this.correspondenceType === this.$t('contact')) {
          filePath = this.contact.ContactId + '/' + this.correspondence.stage + '/' + file.name.trim();
        }
      }else{
        if(this.correspondenceType === this.$t('company')){
          filePath = this.company.Id + '/' + file.name.trim();
        }else if(this.correspondenceType === this.$t('person')){
          filePath = this.person.User + '/' + file.name.trim();
        }else if(this.correspondenceType === this.$t('contact')){
          filePath = this.contact.ContactId + '/' + file.name.trim();
        }
      }

      return filePath;
    },

    /**
     * Deletes and removes the clicked file from the table.
     * @param file
     */
    deleteFile(file){
      this.$confirm.require({
        header: this.$t('swal.deleteFileHeader'),
        message: this.$t('swal.deleteFileText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$store.dispatch('deleteS3Object',file.Key).then(() => {
            this.correspondence.file = this.correspondence.file.filter(foundFile => foundFile.Key !== file.Key);
            this.files = this.files.filter(foundFile => foundFile.Key !== file.Key);
            this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.fileDeleted'), life: 3000 });
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    /**
     * If files are selected but not uploaded, we show a confirm dialog.
     */
    checkIfFilesNotUploaded(){
      if(this.$refs.fileUpload.files.length > 0) {
        this.$confirm.require({
          header: this.$t('swal.submitWithoutUploadingHeader'),
          message: this.$t('swal.submitWithoutUploadingText'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.addCorrespondence();
          },
          reject: () => {
            this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
          }
        });
      }else{
        this.addCorrespondence();
      }
    },

    updateCorrespondenceArray(entity, correspondence) {
      if (!entity.Correspondence) {
        entity.Correspondence = [];
      }
      entity.Correspondence.push(correspondence);
      entity.Correspondence = entity.Correspondence.map(obj => ({
        ...obj,
        timestamp: Number(obj.timestamp)
      }));
    },

    /**
     * If there are no errors, adds the new correspondence to the array
     * and updates the store + posts the update to the server.
     * In case the Key Correspondence doesn't exist yet, we add it.
     * Otherwise, we would receive an error.
     */
    addCorrespondence(){
      this.v$.$validate();
      if(!this.v$.$error) {
        const data = {};
        if (this.correspondenceType === this.$t('company')) {
          data.Company = this.company;
          this.updateCorrespondenceArray(data.Company, this.correspondence);
        } else if (this.correspondenceType === this.$t('person')) {
          data.Person = this.person;
          this.updateCorrespondenceArray(data.Person, this.correspondence);
        } else if (this.correspondenceType === this.$t('contact')) {
          data.Contact = this.contact;
          this.updateCorrespondenceArray(data.Contact, this.correspondence);
        }

        this.$store.dispatch('addCorrespondence',data).then(() => {
          this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.fileDeleted'), life: 3000 });
          this.$emit('close-popup');
        });
      }else{
        this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('errorKeysNeeded') + this.getErrors(), life: 5000 });
      }
    },

    /**
     * Returns a toast with all the errors / required fields.
     * @returns {string}
     */
    getErrors(){
      let errorList = "";
      for(let error of this.v$.$errors){
        if(errorList !== ""){
          errorList = errorList + ", ";
        }
        switch(error.$property){
          case "company":
            errorList = errorList + ' ' + this.$t('company');
            break;
          case "person":
            errorList = errorList + ' ' + this.$t('person');
            break;
          case "contact":
            errorList = errorList + ' ' + this.$t('contact');
            break;
          case "text":
            errorList = errorList + ' ' + this.$t('description');
            break;
        }
      }

      return errorList;
    }
  },
  mounted(){
    this.correspondence.sender = this.username;
  }
}
</script>

<style scoped>

</style>