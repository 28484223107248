<template>
  <div class="flex justify-content-between gap-2 pt-2">
    <Dropdown v-model="correspondence.Phase"
              :options="phaseOptions"
              optionLabel="Name"
              class="w-full"
              :disabled="isReadOnly"
              :class="v$.correspondence.Phase.$error ? 'p-invalid' : ''"
              :placeholder="this.$t('placeholder.phase')"/>
    <Dropdown v-model="correspondence.VisibleTo"
              :options="visibleToOptions"
              class="w-full"
              :disabled="isReadOnly"
              :class="v$.correspondence.VisibleTo.$error ? 'p-invalid' : ''"
              :placeholder="this.$t('placeholder.visibleTo')"/>
  </div>

  <div v-if="correspondence.VisibleTo === 'Members'" class="pt-2">
    <MultiSelect v-model="correspondence.Members"
                 :options="members"
                 optionLabel="Name"
                 class="w-full"
                 :disabled="isReadOnly"
                 filter
                 :placeholder="this.$t('placeholder.selectMembers')"
                 autoFilterFocus/>
  </div>

  <div class="mb-2 mt-2">
    <CustomLabel class="w-full"
                :label="this.$t('assignment')"
                :input="correspondence.stage">
      <Dropdown class="w-full"
                v-model="correspondence.stage"
                :options="stageOptions"
                filter
                :disabled="isReadOnly"
                :placeholder="this.$t('placeholder.assignment')"
                autoFilterFocus />
    </CustomLabel>
  </div>

  <div class="mt-2">
    <ContactSection :company-id="companyId"
                    :contact-props="correspondence.Contact"
                    :contact-options="contactOptions"
                    :is-dropdown="true"
                    :disabled="isReadOnly"
                    :show-load-button="true"
                    @contact-changed="setContact"
                    @get-contacts="getContactsByCompanyId"/>
  </div>

  <div class="pt-2">
    <PrimeTextarea class="w-full"
                   v-model="correspondence.Text"
                   id="Description"
                   maxlength="500"
                   :disabled="isReadOnly"
                   :class="v$.correspondence.Text.$error ? 'p-invalid' : ''"
                   :placeholder="this.$t('placeholder.description')" />
  </div>

  <div v-if="!isReadOnly" class="pt-2">
    <FileUpload ref="fileUpload"
                name="demo[]"
                :customUpload="true"
                @uploader="uploadFiles"
                :multiple="true"
                accept=".jpg,.jpeg,.png,.eml,.msg,.doc,.docx,.pdf,.ppt,.pptx,.txt,.xls,.xlsx"
                :maxFileSize="52000000">
      <template #empty>
        <p>Drag and drop files to here to upload.</p>
      </template>
    </FileUpload>
  </div>

  <div v-if="files.length > 0">
    <AttachmentMaterialTable :attachments="files"
                             :show-delete-button="!isReadOnly"
                             @delete-attachment="deleteFile" />
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import PrimeTextarea from "primevue/textarea";
import FileUpload from "primevue/fileupload";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import Uuidv4Generator from "@/mixins/uuidv4/Uuidv4Generator";
import AttachmentMaterialTable from "@/components/global-components/attachment-material-table/AttachmentMaterialTable";
import S3FileManager from "@/mixins/s3-file-manager/s3-file-manager";
import globalComputedProperties from "@/mixins/global-computed-properties/global-computed-properties";
import ContactSection from "@/components/global-components/contact-section/ContactSection";

export default {
  name: "EditCorrespondence",
  components:{ContactSection, AttachmentMaterialTable, Dropdown, MultiSelect,PrimeTextarea,FileUpload},
  mixins:[Uuidv4Generator,S3FileManager,globalComputedProperties],
  emits: ['correspondenceEdited','closePopup'],
  data(){
    return{
      correspondence:JSON.parse(JSON.stringify(this.correspondenceProp)),
      files:[],
      contactOptions:[],
      emailContent:null,
    }
  },

  props:{
    companyId:String,
    correspondenceProp:Object,
    isReadOnly:Boolean,
    members:Array,
    projectId:String,
  },

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  validations(){
    return {
      correspondence: {
        Phase: {required},
        VisibleTo: {required},
        Text: {required},
      }

    }
  },

  computed:{
    phaseOptions(){
      return this.$store.getters.projectPhases;
    },

    visibleToOptions(){
      return ['Self','Members','All']
    },

    currentUserData(){
      let currentUser = {};
      const responsibles = this.$store.getters.responsibles;
      for(let responsible of responsibles){
        if(responsible.User === this.$store.getters.username){
          currentUser = responsible;
          break;
        }
      }

      return currentUser
    },
    
    stageOptions(){
      const foundTagContainer = this.$store.getters.allTagContainers.find(tagContainer => tagContainer.Coverage === "ticket_stages");
      let stageOptions = [];
      if(foundTagContainer){
        for(let tag of foundTagContainer.Tags){
          const foundCustomList = this.$store.getters.allCustomLists.find(customList => customList.Id === tag);
          if(foundCustomList){
            stageOptions = [...stageOptions, ...foundCustomList.TagNames];
          }
        }
      }

      return stageOptions
    }
  },

  methods:{
    setContact(event){
      this.correspondence.Contact = event;
    },

    pushCreatorIntoMembers(){
      const creator = this.$store.getters.responsibles.filter(responsible => responsible.User === this.$store.getters.username)[0];
      this.correspondence.Members.push({Id:creator.User,Name:creator.FriendlyName,type:"User"});
    },

    uploadFiles(event){
      for (let file of event.files){
        let fileName = this.getFileName(file.name);
        this.$store.commit('setLoading',true);
        const filePath = 'sales/leadsAndProjects/' + this.projectId + '/correspondences/' + this.correspondence.Id + '/' + file.name.trim();
        // Check if it's an email file
        if (file.type.includes('message')) {
          // Wait for email content extraction asynchronously
          this.getEmailText(file).then(() => {
            fileName += ' ' + `${this.$t('from')}: ${this.emailContent.from} ${this.$t('to')}: ${this.emailContent.to} ${this.$t('date')}: ${this.emailContent.date}`;
            this.s3UploadFile(file, filePath).then(() => {
              this.files.push({
                Name:fileName,
                Type:this.getFileType(file.name),
                LastModified:new Date(file.lastModified).toLocaleString(),
                Size:(file.size / (1024*1024)).toFixed(2) + " MB",
                "Key": filePath,
              });

              if(!Array.isArray(this.correspondence.Files)){
                this.correspondence.Files = [];
              }
              this.correspondence.Files.push({path:filePath, name:this.getFileName(file.name)});
              
              this.$store.commit('setLoading',false);
            }).catch((err) => {
              console.log(err);
              this.$store.commit('setLoading',false);
            });
          });
        }else{
          this.s3UploadFile(file, filePath).then(() => {
            this.files.push({
              Name:fileName,
              Type:this.getFileType(file.name),
              LastModified:new Date(file.lastModified).toLocaleString(),
              Size:(file.size / (1024*1024)).toFixed(2) + " MB",
              "Key": filePath,
            });

            if(!Array.isArray(this.correspondence.Files)){
              this.correspondence.Files = [];
            }
            this.correspondence.Files.push({path:filePath, name:this.getFileName(file.name)});
            
            this.$store.commit('setLoading',false);
          }).catch((err) => {
            console.log(err);
            this.$store.commit('setLoading',false);
          });
        }
      }
    },

    getEmailText(file){
      return new Promise((resolve) => {
        const reader = new FileReader();
        
        // Read the file as text or binary, depending on your email file format (.eml or .msg)
        reader.onload = (e) => {
          const emailContent = e.target.result;
          this.emailContent = this.parseEmailContent(emailContent);
          resolve();
        };
        
        // Read as text (suitable for .eml files)
        reader.readAsText(file);
      });
    },

    parseEmailContent(emailContent) {
      // Parsing .eml content (simple approach for text-based emails)
      const emailLines = emailContent.split("\n");

      let from = "", to = "", subject = "", date = "";

      emailLines.forEach(line => {
        if (line.startsWith("From:")) {
          from = line.replace("From:", "").trim();
        } else if (line.startsWith("To:")) {
          to = line.replace("To:", "").trim();
        } else if (line.startsWith("Subject:")) {
          subject = line.replace("Subject:", "").trim();
        } else if (line.startsWith("Date:")) {
          date = line.replace("Date:", "").trim();
        }
      });

      return {
        from,
        to,
        subject,
        date,
      }
    },

    deleteFile(file){
      this.$confirm.require({
        header: this.$t('swal.deleteFileHeader'),
        message: this.$t('swal.deleteFileText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$store.dispatch('deleteS3Object',file.Key).then(() => {
            this.files = this.files.filter(foundFile => foundFile.Key !== file.Key);
            this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.fileDeleted'), life: 3000 });
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    checkIfFilesNotUploaded(){
      if(this.$refs.fileUpload.files.length > 0) {
        this.$confirm.require({
          header: this.$t('swal.submitWithoutUploadingHeader'),
          message: this.$t('swal.submitWithoutUploadingText'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.submitEdit();
          },
          reject: () => {
            this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
          }
        });
      }else{
        this.submitEdit();
      }
    },

    submitEdit(){
      this.v$.$validate();
      if(!this.v$.$error) {
        if(this.correspondence.visibleTo === "Members"){
          if(!this.correspondence.Members){
            this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.noMembersSelected'), life: 3000 });
            return;
          }else{
            const creatorInMembers = this.correspondence.Members.filter(member => member.Id === this.$store.getters.username)[0];

            if(!creatorInMembers) this.pushCreatorIntoMembers();
          }
        }else if(this.correspondence.visibleTo === "Self") {
          this.correspondence.Members = [
            {
              Id: this.currentUserData.User,
              Name: this.currentUserData.FriendlyName,
              type:"User"
            }
          ];
        }

        this.$emit('correspondence-edited',this.correspondence);
        this.$emit('close-popup');
      }else{
        this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('errorKeysNeeded') + this.getErrors(), life: 5000 });
      }
    },

    getErrors(){
      let errorList = "";
      for(let error of this.v$.$errors){
        if(errorList !== ""){
          errorList = errorList + ", ";
        }
        switch(error.$property){
          case "Phase":
            errorList = errorList + ' ' + this.$t('placeholder.phase');
            break;
          case "VisibleTo":
            errorList = errorList + ' ' + this.$t('placeholder.visibleTo');
            break;
          case "Text":
            errorList = errorList + ' ' + this.$t('description')
            break;
        }
      }

      return errorList;
    },

    getContactsByCompanyId(){
      this.$store.commit('setLoading',true);
      this.$store.dispatch("getRequest","getContactsByCompanyId&query=" + this.companyId).then(resp => {
        this.$store.commit('setLoading',false);
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.contactOptions = body.map(contact => ({ Name: contact.ContactName,
            Id:contact.ContactId,
            Email:contact.ContactEmail,
            Phone:contact.OptionalParams ? contact.OptionalParams.Mobile ? contact.OptionalParams.Mobile : "" : "",
            Landline:contact.OptionalParams ? contact.OptionalParams.Landline ? contact.OptionalParams.Landline : "" : ""}));
        }
      });
    },
  },

  mounted(){
    this.files = [];

    if(Array.isArray(this.correspondence.Files)){
      if(this.correspondence.Files.length > 0){
        for(let file of this.correspondence.Files){
          if(file.path.includes('documents/')){
            this.getS3Objects(file.path).then(res => {
              this.files.push(res[0])
            })
          }
        }
          if(this.files.length !== this.correspondence.Files.lenth){
            this.getS3Objects('sales/leadsAndProjects/' + this.projectId + '/correspondences/' + this.correspondence.Id + '/').then(res => {
            // Create a copy of the existing reports
            this.files = [...this.files, ...res];
          });
        }
      }
    }
  }
}
</script>

<style scoped>

</style>