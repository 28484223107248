<template>
  <SmallCard :header="this.$t('offers')" :use-slot="true" :show-button="projectOpen"
             :button-label="this.$t('createNewOffer')" :button-icon="'pi pi-plus'" @button-clicked="createOffer">
    <TabView>
      <TabPanel :header="$t('open') + ' (' + openOffers.length + ')'" >
        <MaterialTable :data-key="'Id'"
                       :table-id="'offers'"
                       :style="{'height': windowHeight}"
                       :data-prop="openOffers"
                       :columns="columns"
                       :has-paginator="true"
                       :rows-count="10"
                       :has-global-filter="true"
                       :filter-prop="filter"
                       :show-delete-button="projectOpen"
                       @row-deleted="deleteOffer"
                       :filter-options="filterOptions"
                       :sortable="true"
                       :has-removable-sort="true"
                       :show-close-button="projectOpen"
                       @close-data="showCloseOfferPopup"
                       :show-toggle-column="true"
                       :key="tableKey"
                       :selection-mode="'single'"
                       :resizable-columns="true"
                       @row-selected="editOffer"
                       :close-button-tool-tip="$t('swal.closeOfferHeader')"
                       :delete-button-tool-tip="$t('swal.deleteOfferHeader')"/>
      </TabPanel>

      <TabPanel :header="$t('sold') + ' (' + soldOffers.length + ')'" >
        <MaterialTable :data-key="'Id'"
                       :table-id="'offers'"
                       :style="{'height': windowHeight}"
                       :data-prop="soldOffers"
                       :columns="columns"
                       :has-paginator="true"
                       :rows-count="10"
                       :has-global-filter="true"
                       :filter-prop="filter"
                       :filter-options="filterOptions"
                       :sortable="true"
                       :has-removable-sort="true"
                       :show-toggle-column="true"
                       :key="tableKey"
                       :selection-mode="'single'"
                       @row-selected="editOffer"
                       :resizable-columns="true"/>
      </TabPanel>

      <TabPanel :header="$t('rejected') + ' (' + rejectedOffers.length + ')'" >
        <MaterialTable :data-key="'Id'"
                       :table-id="'offers'"
                       :style="{'height': windowHeight}"
                       :data-prop="rejectedOffers"
                       :columns="columns"
                       :has-paginator="true"
                       :rows-count="10"
                       :has-global-filter="true"
                       :filter-prop="filter"
                       :filter-options="filterOptions"
                       :sortable="true"
                       :has-removable-sort="true"
                       :show-toggle-column="true"
                       :key="tableKey"
                       :selection-mode="'single'"
                       @row-selected="editOffer"
                       :resizable-columns="true"/>
      </TabPanel>

      <TabPanel :header="$t('replaced') + ' (' + replacedOffers.length + ')'" >
        <MaterialTable :data-key="'Id'"
                       :table-id="'offers'"
                       :style="{'height': windowHeight}"
                       :data-prop="replacedOffers"
                       :columns="columns"
                       :has-paginator="true"
                       :rows-count="10"
                       :has-global-filter="true"
                       :filter-prop="filter"
                       :filter-options="filterOptions"
                       :sortable="true"
                       :has-removable-sort="true"
                       :show-toggle-column="true"
                       :key="tableKey"
                       :selection-mode="'single'"
                       @row-selected="editOffer"
                       :resizable-columns="true"/>
      </TabPanel>

      <TabPanel :header="$t('lost') + ' (' + lostOffers.length + ')'" >
        <MaterialTable :data-key="'Id'"
                       :table-id="'offers'"
                       :style="{'height': windowHeight}"
                       :data-prop="lostOffers"
                       :columns="columns"
                       :has-paginator="true"
                       :rows-count="10"
                       :has-global-filter="true"
                       :filter-prop="filter"
                       :filter-options="filterOptions"
                       :sortable="true"
                       :has-removable-sort="true"
                       :show-toggle-column="true"
                       :key="tableKey"
                       :selection-mode="'single'"
                       @row-selected="editOffer"
                       :resizable-columns="true"/>
      </TabPanel>

      <TabPanel :header="$t('delayed') + ' (' + delayedOffers.length + ')'" >
        <MaterialTable :data-key="'Id'"
                       :table-id="'offers'"
                       :style="{'height': windowHeight}"
                       :data-prop="delayedOffers"
                       :columns="columns"
                       :has-paginator="true"
                       :rows-count="10"
                       :has-global-filter="true"
                       :filter-prop="filter"
                       :filter-options="filterOptions"
                       :sortable="true"
                       :has-removable-sort="true"
                       :show-toggle-column="true"
                       :key="tableKey"
                       :selection-mode="'single'"
                       @row-selected="editOffer"
                       :resizable-columns="true"/>
      </TabPanel>
    </TabView>
  </SmallCard>

  <Popup :header="this.$t('offers')"
         :modal="true"
         :visible="openAddOffer"
         styling="width:50%"
         :position="'top'"
         :show-save-button="true"
         @button-clicked="submitAddOfferPopup"
         @close-popup="openAddOffer = false">
    <AddOffer ref="addOffer" :project="project" @close-popup="closePopup"/>
  </Popup>

  <Popup :header="this.$t('offers')"
         :modal="true"
         :visible="openEditOffer"
         styling="width:50%"
         :position="'top'"
         :show-menu-button="projectOpen"
         :menu-options="editOfferMenuOptions"
         :show-save-button="showSaveButton"
         @button-clicked="submitEditOfferPopup"
         @close-popup="openEditOffer = false">
    <EditOffer ref="editOffer"
               :members="project.Members"
               :project-id="project.Id"
               :offer-prop="selectedOffer"
               :projectOpen="projectOpen"
               :disabled="selectedOffer.Status !== '10' || !projectOpen"
               @reopen-offer="reopenOffer"
               @close-popup="closePopup"/>
  </Popup>

  <Popup :maximizable="false"
         :modal="true"
         :visible="closingOffer"
         :header="this.$t('swal.closeOfferText')"
         styling="width:500px"
         :show-save-button="true"
         @button-clicked="submitCloseOfferPopup"
         @close-popup="closingOffer = false">
    <CloseOffer ref="closeOffer" @close-offer="closeOffer" />
  </Popup>
</template>

<script>
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import CloseOffer from "@/components/projects/dashboard/offers/close-offer/CloseOffer";
import Popup from "@/components/global-components/popup/Popup";
import AddOffer from "@/components/projects/dashboard/offers/add-offer/AddOffer";
import EditOffer from "@/components/projects/dashboard/offers/edit-offer/EditOffer";
import SmallCard from "@/components/global-components/card/SmallCard";
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import {FilterMatchMode} from "primevue/api";
import globalComputedProperties from "@/mixins/global-computed-properties/global-computed-properties";
import S3FileManager from "@/mixins/s3-file-manager/s3-file-manager";

export default {
  name: "OfferComponent",
  components:{SmallCard, EditOffer, AddOffer, Popup, MaterialTable, CloseOffer, TabView, TabPanel},
  mixins:[globalComputedProperties, S3FileManager],
  data(){
    return {
      closingOffer:false,
      columns:[
        { field: 'Title', header: this.$t('title') },
        { field: 'Members', header: this.$t('members') },
        { field: 'OfferDate', header: this.$t('offerDate') },
        { field: 'DeliveryDate', header: this.$t('deliveryDate') },
        { field: 'Probability', header: this.$t('probability') },
        { field: 'SaleValue', header: this.$t('budget') },
        { field: 'OfferValue', header: this.$t('offerValue') },
        { field: 'BonusOption', header: this.$t('bonusOption') },
        { field: 'OfferNumber', header: this.$t('offerNumber') },
        { field: 'OfferType', header: this.$t('offerType') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Members: { value: null, matchMode: FilterMatchMode.IN },
        OfferDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
        DeliveryDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Probability: { value: null, matchMode: FilterMatchMode.CONTAINS },
        SaleValue: { value: null, matchMode: FilterMatchMode.CONTAINS },
        OfferValue: { value: null, matchMode: FilterMatchMode.CONTAINS },
        BonusOption: { value: null, matchMode: FilterMatchMode.CONTAINS },
        OfferNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        OfferType: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['Title', 'Probability', 'SaleValue', 'OfferValue', 'BonusOption', 'OfferNumber', 'OfferType'],
      openAddOffer: false,
      openEditOffer: false,
      selectedOffer: null,
      tableKey:0,
      editOfferMenuOptions:[],
      showSaveButton:false,
      windowHeight: '',
    }
  },
  props:{
    project:Object,
    projectOpen:Boolean,
  },
  computed:{
    openOffers(){
     return this.setOffers("10");
    },

    soldOffers(){
      return this.setOffers("20");
    },

    rejectedOffers(){
      return this.setOffers("30");
    },

    replacedOffers(){
      return this.setOffers("40");
    },

    lostOffers(){
      return this.setOffers("50");
    },

    delayedOffers(){
      return this.setOffers("60");
    },
  },

  methods:{
    /**
     * Sets all offers visible, depending on VisibleTo.
     * If VisibleTo = "Members" or "Self", we iterate through all members and see if the Id matches and push them into the array.
     * Else VisibleTo is "All" and we push it into the array.
     * @param status
     * @returns {*[]}
     */
    setOffers(status){
      const offers = [];
      const allOffers = this.$store.getters.offers;
      console.log(allOffers)
      for(let offer of allOffers){
        if(offer.Status === status){
          if(offer.ProjectId === this.project.Id){
            if(offer.VisibleTo === "Members" ||offer.VisibleTo === "Self"){
              for(let member of offer.Members){
                if(member.Name === this.username){
                  offers.push(offer);
                }
              }
            }else{
              offers.push(offer);
            }
          }
        }
      }

      return offers;
    },

    createEditOfferMenuOptions(offer){
      const optionsMenu = []
      if(offer.Status === '10'){
        if(this.$store.getters.userType.toLowerCase() === "admin"){
          optionsMenu.push(
              { label: this.$t('swal.deleteOfferHeader'), icon: 'pi pi-fw pi-trash',
                command: () => { this.deleteOffer(offer);}
              },
              { separator : true},
          )
        }
        optionsMenu.push(
            { label: this.$t('swal.closeOfferHeader'), icon: 'pi pi-fw pi-check',
              command: () => {this.editOfferClosePopup(offer);}
            },
        )
      }else{
        optionsMenu.push(
            { label: this.$t('swal.reopenOfferHeader'), icon: 'pi pi-fw pi-check',
              command: () => {this.reopenOffer(offer);}
            },
        )
      }

      this.editOfferMenuOptions = optionsMenu;
    },

    createOffer(){
      this.openAddOffer = true;
    },

    closePopup(){
      this.openAddOffer = false;
      this.openEditOffer = false;
      this.selectedOffer = null;
      this.closingOffer = false;
    },

    editOffer(offer){
      this.showSaveButton = offer.Status === '10' && this.projectOpen;
      this.createEditOfferMenuOptions(offer);
      this.selectedOffer = offer;
      this.openEditOffer = true;
    },
    showCloseOfferPopup(offer){
      this.closingOffer = true;
      this.selectedOffer = offer;
    },
    /**
     * Shows an alert that asks for confirmation to close the offer.
     * If we accept we show a short confirmation popup in the top-right corner.
     * If we decline we show a short reject popup in the top-right corner.
     * @param status
     */
    closeOffer(status){
      this.selectedOffer.Closed = new Date().getTime();
      this.selectedOffer.Status = status;
      this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.offerClosed'), life: 3000 });
      this.$store.dispatch('closeOffer',this.selectedOffer).then(() => {
        this.closePopup();
      });
    },

    reopenOffer(offer){
      this.$confirm.require({
        header: this.$t('swal.reopenOfferHeader'),
        message: this.$t('swal.reopenOfferText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          offer.Status = "10";
          this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.offerReopened'), life: 3000 });
          this.$store.dispatch('reopenOffer',offer).then(() => {
            this.closePopup();
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    /**
     * Shows an alert that asks for confirmation to delete the offer.
     * If we accept we show a short confirmation popup in the top-right corner and
     * remove the offer from the store.
     * If we decline we show a short reject popup in the top-right corner.
     * @param offer
     */
    deleteOffer(offer){
      this.$confirm.require({
        header: this.$t('swal.deleteOfferHeader'),
        message: this.$t('swal.deleteOfferText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.offerDeleted'), life: 3000 });
          this.$store.dispatch('deleteOffer',offer).then(() => {
            this.deleteFolder('sales/leadsAndProjects/' + this.projectId + '/offers/' + offer.Id +'/');
            this.closePopup();
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    submitAddOfferPopup(){
      this.$refs.addOffer.checkIfFilesNotUploaded();
    },

    submitEditOfferPopup(){
      this.$refs.editOffer.checkIfFilesNotUploaded();
    },

    submitCloseOfferPopup(){
      this.$refs.closeOffer.emitCloseOffer();
    },

    editOfferClosePopup(){
      this.$refs.editOffer.closingOffer = true;
    }
  },

  mounted(){
    this.windowHeight = window.innerHeight * 0.52 + 'px';
  }
}
</script>

<style scoped>
:deep(.p-tabview-panels){
  padding-left:0 !important;
  padding-right:0 !important;
}
</style>