<template>
  <!--
  If the project is lost (Result = 10), we show a crossed out box on which phase the project was lost.
  Otherwise, we show a checkmark box for finished / completed phases and an empty box for future phases.
  -->
  <div class="resourceCheckBoardContainer col-12">
    <div v-for="phase of projectPhases" :key="phase" class="cursor-pointer" @click="emitPhaseClicked(phase)">
      <svg v-if="project.Status !== '10' && phase.Number === project.Phase.Number" xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="rgb(34, 197, 94)"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg>
      <svg v-else-if="project.Result === '10' && phase.Number < project.Phase.Number" xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="rgb(26, 115, 232)"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg>
      <svg v-else-if="project.Result === '10' && phase.Number === project.Phase.Number" xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px" fill="red"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Z"/></svg>
      <svg v-else-if="project.Result !== '10' && phase.Number <= project.Phase.Number" xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="rgb(26, 115, 232)"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg>
      <svg v-else xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="grey"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/></svg>
      <span :style="clickedPhase && phase.Number === clickedPhase.Number ? 'font-weight:bold' : ''">{{phase.Name}}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: "ProjectPhaseTimeline",
  emits: ['resetClickedPhase','phaseClicked'],
  props:{
    project:Object,
    clickedPhase:{Object},
  },

  computed:{
    projectPhases(){
      return this.$store.getters.projectPhases;
    },
  },

  methods:{
    /**
     * Emits reset-clicked-phase if this.clickedPhase equals the clicked phase and resets this.clickedPhase.
     * Otherwise, we clicked a new phase and emit phase-clicked.
     * @param phase
     */
    emitPhaseClicked(phase){
      if(this.clickedPhase && this.clickedPhase.Number === phase.Number){
        this.$emit('reset-clicked-phase');
      }else{
        this.$emit('phase-clicked',phase);
      }
    }

  },
}
</script>

<style scoped>

.resourceCheckBoardContainer{
  display: flex;
  gap: 20px;
  text-align: center;
  align-items: flex-start;
  justify-content: space-between;
  border-top: 1px solid grey;
  margin-top: 10px;
  position: relative;
  margin-bottom:10px;
}

.resourceCheckBoardContainer div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -17px;
  max-width: 200px;
}

svg{
  background-color: white;
}

</style>