<template>
  <Dropdown v-model="task" :options="tasks" optionLabel="Title" class="w-full mb-2"
            :class="v$.task.$error ? 'p-invalid' : ''" :placeholder="this.$t('placeholder.selectTask')"/>
  <div class="flex justify-content-end">
    <PrimeButton :label="this.$t('confirm')" icon="pi pi-check" outlined rounded @click="confirmSelectedTask" />
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";
import PrimeButton from "primevue/button";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators/dist";

export default {
  name: "SelectTaskDropdown",
  components:{Dropdown, PrimeButton},
  emits:['emitTask'],
  data(){
    return{
      task:null,
    }
  },

  props:{
    projectId:String,
  },

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  validations(){
    return {
      task:{required},
    }
  },

  computed:{
    tasks(){
      const tasks = this.$store.getters.tasks;
      const filteredTasks = [];
      for(let task of tasks){
        if(task.ProjectId === this.projectId && task.VisitReportId){
          filteredTasks.push(task);
        }
      }
      return filteredTasks;
    }
  },

  methods:{
    confirmSelectedTask(){
      this.v$.$validate();
      if(!this.v$.$error) {
        this.emitTask();
      }
    },

    emitTask(){
      this.$emit('emit-task',this.task);
    }
  }
}
</script>

<style scoped>

</style>