<template>
  <div style="min-height: 600px">
    <div class="col-lg-12 control-section">
      <div class="content-wrapper">
        <ejs-grid id="Grid"
                  ref="grid"
                  :dataSource="gridDataSource"
                  width="100%"
                  :allowPaging="true"
                  :allowSelection="true"
                  :toolbar='toolbarOptions'
                  :allowExcelExport='true'
                  :toolbarClick='toolbarClick'
                  :allowSorting="true"
                  :allowFiltering="true"
                  :filterSettings="filterOptions"
                  :pageSettings="pageSettings"
                  @recordClick="goToProjectDashboard"
                  @excelQueryCellInfo="onExcelQueryCellInfo" >
          <e-columns>
            <e-column field="Title" :headerText="$t('title')" type="string" />
            <e-column field="Responsible" :headerText="$t('responsible')" type="string" />
            <e-column field="Company" :headerText="$t('company')" type="string" />
            <e-column field="Creator" :headerText="$t('creator')" type="string" />
            <e-column field="Description" :headerText="$t('description')" type="string" />
            <e-column field="Phase" headerText="Phase" type="string" />
            <e-column field="ProjectType" :headerText="$t('type')" type="string" />
            <e-column field="ProjectClassification" :headerText="$t('project') + ' ' +$t('classification')" type="string" />
            <e-column field="ProjectTags" :headerText="$t('project') + ' Tags'" type="string" />
            <e-column field="LeadClassification" :headerText="'Lead ' + $t('classification')" type="string" />
            <e-column field="LeadTags" headerText="Lead Tags" type="string" />
            <e-column field="CreationDate" :headerText="$t('creationDate')" type="date" format="dd.MM.yyyy" />
          </e-columns>
        </ejs-grid>
      </div>
    </div>
  </div>
</template>

<script>
import { ColumnsDirective, ColumnDirective } from "@syncfusion/ej2-vue-querybuilder";
import { GridComponent, Page, Selection, Sort, Toolbar, ExcelExport, Filter } from "@syncfusion/ej2-vue-grids";
import QueryBuilderFunctions from "@/mixins/query-builder-functions/queryBuilderFunctions";

export default {
  name: "ProjectQueryTable",
  mixins:[QueryBuilderFunctions],
  data() {
    return {
      filterOptions:{type:'Excel'},
      gridDataSource:[],
      pageSettings: { pageSize: 20, pageCount: 5 },
      importRules: {},
    };
  },
  components: {
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective,
    'ejs-grid': GridComponent,
  },
  computed:{
    toolbarOptions(){
      const options = ['ExcelExport'];

      if(this.lastEvaluatedKey){
        options.push(
          { text: 'Load all data', tooltipText: 'Load all data', prefixIcon: 'e-refresh', id: 'toolbar_load_all', align: 'Right'},
          { text: 'Load more data', tooltipText: 'Load more data', prefixIcon: 'e-refresh', id: 'toolbar_load', align: 'Right' });
      }
      return options;
    },

    lastEvaluatedKey(){
      return this.$store.getters.openProjectsLastEvaluatedKey;
    }
  },
  methods: {
    toolbarClick(args) {
      if (args.item.id === 'Grid_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        this.$refs.grid.excelExport();
      }

      if (args.item.id === 'toolbar_load') {
        this.getMoreProjects();
      }

      if(args.item.id === 'toolbar_load_all'){
        this.getAllProjects();
      }
    },

    getMoreProjects(){
      return new Promise((resolve) => {
        if(this.lastEvaluatedKey && this.lastEvaluatedKey.Created && this.lastEvaluatedKey.Id){
        const created = Number(this.lastEvaluatedKey["Created"]);
        const id = this.lastEvaluatedKey["Id"];
        this.$store.dispatch("getRequest","getMoreProjects&query=" + [id, created]).then(resp => {
          if (resp && resp.statusCode === 200) {
            const body = JSON.parse(resp.body);
            this.$store.commit('setOpenProjects', body.Items);
            this.prepareDataSource(body.Items);

            if (body.LastEvaluatedKey && body.Items.length > 0) {
              this.$store.commit('setOpenProjectsLastEvaluatedKey', body.LastEvaluatedKey);
            }else{
              this.$store.commit('setOpenProjectsLastEvaluatedKey', null);
            }

            resolve();
          }else{
            this.$toast.add({ severity: 'error', summary: this.$t('Info'), detail: this.$t('swal.allDataReceived'), life: 5000 });
          }
        });
      }
      });
    },

    prepareDataSource(projects){
      const src = [];
      for (let project of projects){
        let obj = {};
        obj["Title"] = project["Title"];
        obj["Creator"] = project["Creator"];
        obj["Description"] = project["Description"];
        obj["Prio"] = project["Prio"];
        obj["ProjectType"] = project["ProjectType"] ? project["ProjectType"] : "-";
        obj["Id"] = project["Id"];

        // the table cannot display nested values so we modify / prepare the data.
        obj["Company"] = this.getValFromObject(project.Company, "Name") + " " + this.getValFromObject(project.Company, "CompanyNumber");
        obj["Responsible"] = this.getValFromObject(project.Responsible, "Name");
        obj["Phase"] =  this.getValFromObject(project.Phase, "Name");
        obj["ProjectClassification"] = this.getValFromObject(project.ProjectTagContainer, "ContainerName");
        obj["LeadClassification"] = this.getValFromObject(project.LeadTagContainer, "ContainerName");

        obj["ProjectTags"] = project.ProjectTags ? this.getTags(project.ProjectTags) : "";
        obj["LeadTags"] = project.LeadTags ? this.getTags(project.LeadTags) : "";

        obj["CreationDate"] = new Date(parseInt(project.Created));

        src.push(obj);
      }
      this.gridDataSource = [...this.gridDataSource, ...src];
    },

    getAllProjects(){
      this.getMoreProjects().then(()=>{
        if(this.lastEvaluatedKey) {
          this.getAllProjects();
        }
      });
    },

    /**
     * Changed the unix into a date for the Excel export.
     * @param args
     */
    onExcelQueryCellInfo(args) {
      if (args.column.field === 'CreationDate') {
        const date = new Date(args.value);
        args.value = date.toLocaleDateString();
      }
    },

    goToProjectDashboard(args){
      const projectId = args.rowData.Id;
      this.$router.push({
        path:"/projects/dashboard/" + projectId,
        params:projectId
      });
    }
  },
  mounted(){
    this.prepareDataSource(this.$store.getters.projects);
  },
  provide: {
    grid: [Page, Selection, Sort,Toolbar, ExcelExport, Filter]
  }
};
</script>


