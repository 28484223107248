<template>
  <MaterialTable :data-key="'Id'"
                 :table-id="'leadsOrProjects'"
                 :style="{'height': windowHeight}"
                 @row-selected="emitSelectionChanged"
                 :data-prop="optionsList"
                 :columns="columns"
                 :has-paginator="true"
                 :rows-count="10"
                 :has-global-filter="true"
                 :filter-prop="filter"
                 :filter-options="filterOptions"
                 :sortable="true"
                 :show-toggle-column="true"
                 :has-removable-sort="true"
                 :key="tableKey"
                 :selection-mode="'single'"
                 :resizable-columns="true"/>
</template>

<script>
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import {FilterMatchMode} from "primevue/api";
export default {
  name: "LeadsOrProjectSearchTable",
  components: {MaterialTable},
  emits:['selectionChanged'],
  props:{
    optionsList:Array,
  },
  data(){
    return{
      columns:[
        { field: 'CreationNumber', header: this.$t('number') },
        { field: 'Title', header: this.$t('title') },
        { field: 'Description', header: this.$t('description') },
        { field: 'Creator', header: this.$t('creator') },
        { field: 'Responsible.Name', header: this.$t('responsible') },
        { field: 'Created', header: this.$t('createdOn') },
        { field: 'Company.Name', header: this.$t('company') },
        { field: 'OpenSince', header: this.$t('openSince') },
        { field: 'Supplier', header: this.$t('supplier')},
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CreationNumber:{value: null, matchMode: FilterMatchMode.CONTAINS},
        Title: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Creator: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Responsible.Name': { value: null, matchMode: FilterMatchMode.IN },
        Created: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Company.Name': { value: null, matchMode: FilterMatchMode.IN },
        OpenSince: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Supplier: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['CreationNumber', 'Title', 'Description', 'Creator', 'Responsible.Name', 'Company.Name', 'Supplier'],
      tableKey:0,
      windowHeight: '',
    }
  },
  methods:{
    emitSelectionChanged(item){
      this.$emit('selection-changed',item);
    }
  },

  mounted(){
    this.windowHeight = window.innerHeight * 0.6 + 'px';
  }
}
</script>

<style scoped>

</style>