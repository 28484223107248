<template>
  <div v-if="showCompleteHeader" class="flex justify-content-between">
    <div class="flex align-items-start flex-column md:flex-row">
      <div class="mr-5 mb-3 lg:mb-0" style="width: 90px;height: 90px">
        <i style="font-size: 4rem" class="text-bluegray-500 pi pi-building"></i>
      </div>
      <div>
        <span class="text-900 font-medium text-3xl">{{company.Name}}</span>
        <div class="flex align-items-center flex-wrap text-sm">
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{this.$t('country')}}</span>
            <div>{{company.Country}}</div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{this.$t('address')}}</span>
            <div>{{company.Address}}</div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{this.$t('location')}}</span>
            <div>{{company.PostalCode}}, {{company.City}}</div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{this.$t('website')}}</span>
            <div>
              <a :href="addHttps(company.Website)" target="_blank">{{company.Website}}</a>
            </div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{this.$t('phoneNumber')}}</span>
            <div>{{company.Phone}}</div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{this.$t('email')}}</span>
            <div>{{company.Email}}</div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{this.$t('companyNumber')}}</span>
            <div>{{company.CompanyNumber}}</div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{this.$t('responsible')}}</span>
            <div>{{company.Responsible}}</div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{this.$t('notes')}}</span>
            <div style="max-width: 250px; overflow-x: auto; white-space: nowrap;">{{company.Info}}</div>
          </div>
          <div v-if="company.TagContainer" class="mr-5 mt-3">
            <span class="font-medium text-500">{{this.$t('classification')}}</span>
            <div>{{getCompanyTagContainer()}}</div>
          </div>
          <div v-for="tag of company.Tags" class="mr-5 mt-3" :key="tag">
            <span class="font-medium text-500">{{tag.labelName}}</span>
            <div style="max-width: 250px; overflow-x: auto; white-space: nowrap;">
              {{tag && tag.selectedValue.length > 0 ? tag.selectedValue.toString() : '-'}}
            </div>
          </div>
          <div class="mr-5 mt-3 cursor-pointer" @click="goToLastContact">
            <span class="font-medium text-500">{{this.$t('lastContact')}}</span>
            <div>{{lastContact ? lastContact.Text : '-'}}</div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <PrimeButton v-if="companyIsFavorite" icon="pi pi-star-fill" plain text @click="unfavoriseCompany" />
      <PrimeButton v-else icon="pi pi-star" plain text @click="favoriseCompany" />
      <PrimeButton icon="pi pi-angle-up" rounded @click="setShowCompleteHeader(false)" />
      <PrimeButton v-if="showDashboardViewer" icon="pi pi-angle-right" rounded @click="setShowDashboardViewer(false)" />
      <PrimeButton v-else icon="pi pi-angle-left" rounded @click="setShowDashboardViewer(true)" />
    </div>
  </div>

  <div v-else>
    <div class="flex justify-content-between flex-column md:flex-row">
      <div>
        <span class="text-900 font-medium text-3xl">{{company.Name}}</span>
      </div>
      <div>
        <PrimeButton v-if="companyIsFavorite" icon="pi pi-star-fill" plain text @click="unfavoriseCompany" />
        <PrimeButton v-else icon="pi pi-star" plain text @click="favoriseCompany" />
        <PrimeButton icon="pi pi-angle-down" rounded @click="setShowCompleteHeader(true)" />
        <PrimeButton v-if="showDashboardViewer" icon="pi pi-angle-right" rounded @click="setShowDashboardViewer(false)" />
        <PrimeButton v-else icon="pi pi-angle-left" rounded @click="setShowDashboardViewer(true)" />
      </div>
    </div>
  </div>

</template>

<script>
import PrimeButton from "primevue/button";

export default {
  name: "CompanyDashboardHeader",
  components:{PrimeButton},
  emits:['showCards', 'showDashboardViewer'],
  props:{
    company:Object,
    lastContact:Object,
    showDashboardViewer:Boolean,
  },
  data(){
    return{
      favorisedCompanies:[],
      showCompleteHeader:true,
    }
  },
  computed:{
    companyIsFavorite(){
      if (localStorage.getItem("favorisedCompanies") !== null) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.favorisedCompanies = JSON.parse(localStorage.getItem("favorisedCompanies"));
      }
      if(this.favorisedCompanies.length > 0){
        for(let favorisedCompany of this.favorisedCompanies){
          if(favorisedCompany.Id === this.company.Id){
            return true;
          }
        }
      }
      return false;
    },

    tagContainers(){
      return this.$store.getters.allTagContainers;
    }
  },
  methods:{
    favoriseCompany(){
      const company = {
        Id:this.company.Id,
        Name:this.company.Name,
        CompanyNumber:this.company.CompanyNumber,
        Address:this.company.Address,
        City:this.company.PostalCode + ', ' + this.company.City,
      }
      this.favorisedCompanies.push(company);

      window.localStorage.setItem("favorisedCompanies", JSON.stringify(this.favorisedCompanies));
    },

    unfavoriseCompany(){
      this.favorisedCompanies = this.favorisedCompanies.filter(company => company.Id !== this.company.Id);

      window.localStorage.setItem("favorisedCompanies", JSON.stringify(this.favorisedCompanies));
    },

    addHttps(url) {
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        return 'https://' + url;
      }
      return url;
    },

    getCompanyTagContainer(){
      const found = this.tagContainers.find(tagContainer => tagContainer.id === this.company.TagContainer);
      return found ? found.ContainerName : '-';
    },

    setShowCompleteHeader(trueOrFalse){
      this.showCompleteHeader = trueOrFalse;
      this.$emit('show-cards',trueOrFalse);
    },

    setShowDashboardViewer(trueOrFalse){
      this.$emit('show-dashboard-viewer',trueOrFalse);
    },

    goToLastContact(){
      if(this.lastContact){
        if(this.lastContact.Type === 'Project'){
          this.$router.push({
            path:"/projects/dashboard/" + this.lastContact.ProjectId,
            params:this.lastContact.ProjectId
          });
        }else{
          this.$toast.add({ severity: 'info', summary: this.$t('info'), detail: this.$t('swal.leadContactIs') + ' ' + this.lastContact.ProjectTitle, });
        }
      }else{
        this.$toast.add({ severity: 'info', summary: this.$t('info'), detail: this.$t('swal.noContactInitiated'), life:3000});
      }
    },
  }
}
</script>

<style scoped>

</style>