<template>
  <div style="min-height: 600px">
    <div class="col-lg-12 control-section">
      <div class="content-wrapper">
        <ejs-grid id="Grid"
                  ref="grid"
                  :dataSource="gridDataSource"
                  width="100%"
                  :allowPaging="true"
                  :allowSelection="true"
                  :toolbar='toolbarOptions'
                  :allowExcelExport='true'
                  :toolbarClick='toolbarClick'
                  :allowSorting="true"
                  :allowFiltering="true"
                  :filterSettings="filterOptions"
                  :pageSettings="pageSettings">
          <e-columns>
            <e-column field="FriendlyName" :headerText="$t('name')" type="string" />
            <e-column field="CompanyName" :headerText="$t('company')" type="string" />
            <e-column field="Email" :headerText="$t('email')" type="string" />
            <e-column field="Department" :headerText="$t('department')" type="string" />
            <e-column field="Position" :headerText="$t('position')" type="string" />
            <e-column field="Landline" :headerText="$t('landline')" type="string" />
            <e-column field="Mobile" :headerText="$t('mobile')" type="string" />
          </e-columns>
        </ejs-grid>
      </div>
    </div>
  </div>
</template>

<script>
import {ColumnDirective, ColumnsDirective} from "@syncfusion/ej2-vue-querybuilder";
import {ExcelExport, GridComponent, Page, Selection, Sort, Toolbar, Filter} from "@syncfusion/ej2-vue-grids";
import QueryBuilderFunctions from "@/mixins/query-builder-functions/queryBuilderFunctions";

export default {
  name: "PersonQueryTable",
  mixins:[QueryBuilderFunctions],
  data() {
    return {
      filterOptions:{type:'Excel'},
      pageSettings: { pageSize: 20, pageCount: 5 },
      importRules: {},
    };
  },
  components: {
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective,
    'ejs-grid': GridComponent,
  },
  computed:{
    toolbarOptions(){
      const options = ['ExcelExport'];

      if(this.lastEvaluatedKey){
        options.push(
            { text: 'Load all data', tooltipText: 'Load all data', prefixIcon: 'e-refresh', id: 'toolbar_load_all', align: 'Right'},
            { text: 'Load more data', tooltipText: 'Load more data', prefixIcon: 'e-refresh', id: 'toolbar_load', align: 'Right' });
      }
      return options;
    },

    gridDataSource(){
      const contactQueryData = this.$store.getters.contactQueryData;

      const src = []
      for(let contact of contactQueryData){
        let obj = {};
        obj["FriendlyName"] = contact["ContactName"];
        obj["CompanyName"] = contact["CompanyName"];
        obj["Email"] = contact["ContactEmail"];

        obj["Landline"] = this.getValFromObject(contact.OptionalParams, "Landline");
        obj["Mobile"] = this.getValFromObject(contact.OptionalParams, "Mobile");
        obj["Department"] = this.getValFromObject(contact.OptionalParams, "Department");
        obj["Position"] = this.getValFromObject(contact.OptionalParams, "Position");

        src.push(obj);
      }

      return src;
    },

    lastEvaluatedKey(){
      return this.$store.getters.contactLastEvaluatedKey;
    }
  },
  methods: {
    toolbarClick(args) {
      if (args.item.id === 'Grid_excelexport') {
        this.$refs.grid.excelExport();
      }

      if (args.item.id === 'toolbar_load') {
        this.getMoreContacts().then((data) => {
          this.$store.commit('setContactQueryData', data);
        });
      }

      if(args.item.id === 'toolbar_load_all'){
        this.getAllContacts();
      }
    },

    getContactsData(){
      if(this.gridDataSource.length === 0){
        this.$store.dispatch('getRequest',"getAdminContacts").then((data)=>{
          if(data && data.statusCode && data.statusCode === 200){
            const body = JSON.parse(data.body);
            this.$store.commit('setContactLastEvaluatedKey', body["LastEvaluatedKey"]);
            this.$store.commit('setContactQueryData', body['Items']);
          }
        });
      }
    },

    getMoreContacts(){
      return new Promise((resolve) => {
        this.$store.dispatch('getRequest',"getMoreAdminContacts&query=" + this.lastEvaluatedKey["ContactId"]).then((data)=>{
          if(data && data.statusCode && data.statusCode === 200){
            const body = JSON.parse(data.body);
            this.$store.commit('setContactLastEvaluatedKey', body["LastEvaluatedKey"]);
            resolve(body['Items']);
          }
        });
      })
    },

    getAllContacts(){
      this.getMoreContacts().then((data)=>{
        this.$store.commit('setContactQueryData', data);
        if(this.lastEvaluatedKey) {
          this.getAllContacts();
        }
      });
    },

  },
  provide: {
    grid: [Page, Selection, Sort,Toolbar, ExcelExport, Filter]
  },

  mounted(){
    this.getContactsData();
  }
}
</script>

<style scoped>

</style>