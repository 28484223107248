<template>
  <div style="min-height: 600px">
    <div class="col-lg-12 control-section" style="min-height: auto;">
      <ejs-querybuilder id="correspondenceQueryBuilder" ref="correspondenceQueryBuilder" :dataSource="prepareDataSource" :rule="importRules" width="100%" :ruleChange="updateRule">
        <e-columns>
          <e-column field="Title" :label="$t('title')" type="string" :operators="[{key:'Equal', value:'equal'},{key:'Contains', value:'Contains'}]" />
          <e-column field="Type" :label="$t('type')" type="string" :operators="[{key:'Equal', value:'equal'},{key:'Contains', value:'Contains'}]" />
          <e-column field="Company" :label="$t('company')" type="string" :operators="[{key:'Equal', value:'equal'},{key:'Contains', value:'Contains'}]" />
          <e-column field="Creator" :label="$t('creator')" type="string" :operators="[{key:'Equal', value:'equal'},{key:'Contains', value:'Contains'}]" />
          <e-column field="ReportType" :label="$t('reportType')" type="string" :operators="[{key:'Equal', value:'equal'},{key:'Contains', value:'Contains'}]" />
          <e-column field="Date" :label="$t('creationDate')" type="date" :operators="[{key:'Equal', value:'equal'},{key:'Contains', value:'Contains'}]" />
        </e-columns>
      </ejs-querybuilder>
    </div>
    <div class="col-lg-12 control-section">
      <div class="content-wrapper">
        <ejs-grid id="Grid" ref="grid" :dataSource="gridDataSource" width="100%" :allowPaging="true" :allowSelection="true"
                  :toolbar='toolbarOptions' :allowExcelExport='true' :toolbarClick='toolbarClick' :allowSorting="true"
                  :allowFiltering="true" :filterSettings="filterOptions" :pageSettings="pageSettings" :created="onGridCreated">
          <e-columns>
            <e-column field="Title" :headerText="$t('title')" type="string" />
            <e-column field="Type" :headerText="$t('type')" type="string" />
            <e-column field="Company" :headerText="$t('company')" type="string" />
            <e-column field="Creator" :headerText="$t('creator')" type="string" />
            <e-column field="ReportType" :headerText="$t('reportType')" type="string" />
            <e-column field="Date" :headerText="$t('creationDate')" type="date" format="dd.MM.yyyy" />
          </e-columns>
        </ejs-grid>
      </div>
    </div>
  </div>
</template>

<script>
import {ColumnDirective, ColumnsDirective, QueryBuilderComponent} from "@syncfusion/ej2-vue-querybuilder";
import {ExcelExport, GridComponent, Page, Selection, Sort, Toolbar, Filter} from "@syncfusion/ej2-vue-grids";
import { DataManager, Query } from '@syncfusion/ej2-data';
import {isNullOrUndefined} from "@syncfusion/ej2-base";
import QueryBuilderFunctions from "@/mixins/query-builder-functions/queryBuilderFunctions";
import getStoreData from "@/mixins/get-store-data/getStoreData";

export default {
  name: "CorrespondenceQueryTable",
  mixins:[QueryBuilderFunctions, getStoreData],
  data() {
    return {
      filterOptions:{type:'Excel'},
      gridDataSource: [],
      pageSettings: { pageSize: 20, pageCount: 5 },
      importRules: {},
      toolbarOptions: ['ExcelExport']
    };
  },
  components: {
    'ejs-querybuilder': QueryBuilderComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective,
    'ejs-grid': GridComponent,
  },
  computed:{
    closedLeads(){
      return this.$store.getters.closedLeads;
    },

    closedProjects(){
      return this.$store.getters.closedProjects;
    },

    prepareDataSource(){
      const leads = this.$store.getters.leads;
      const projects = this.$store.getters.projects;
      let src = [];
      let data = [...leads, ...projects];
      if(this.closedLeads && this.closedProjects){
        data = [...data, ...this.closedLeads, ...this.closedProjects];
      }

      for(let object of data){
        if(object.Correspondence && object.Correspondence.length > 0){
          for(let correspondence of object.Correspondence){
            if(correspondence.Type && correspondence.Type !== 'Beitrag'){
              let obj = {};

              obj["Title"] = object["Title"];
              obj["Type"] = this.$t(object["Type"].toLowerCase());
              obj["ReportType"] = correspondence["Type"];
              obj["Creator"] = correspondence["Author"];
              obj["Date"] = new Date(Number(correspondence["Unix"]));
              obj["Company"] = this.getValFromObject(object.Company, "Name");

              src.push(obj);
            }
          }
        }
      }
      return src;
    },
  },
  methods: {
    /**
     * Goes through all leads & projects & closedLeads / -Projects if they exist,
     * to create an array with all reports and necessary info.
     * @returns {*[]}
     */
    createGridDataSource(){
      const leads = this.$store.getters.leads;
      const projects = this.$store.getters.projects;
      let src = [];
      let data = [...leads, ...projects];
      if(this.closedLeads && this.closedProjects){
        data = [...data, ...this.closedLeads, ...this.closedProjects];
      }

      for(let object of data){
        if(object.Correspondence && object.Correspondence.length > 0){
          for(let correspondence of object.Correspondence){
            if(correspondence.Type && correspondence.Type !== 'Beitrag'){
              let obj = {};

              obj["Title"] = object["Title"];
              obj["Type"] = object["Type"];
              obj["ReportType"] = correspondence["Type"];
              obj["Creator"] = correspondence["Author"];
              obj["Date"] = correspondence["timestamp"];
              obj["Company"] = this.getValFromObject(object.Company, "Name");

              src.push(obj);
            }
          }
        }
      }
      return src;
    },

    toolbarClick(args) {
      if (args.item.id === 'Grid_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        this.$refs.grid.excelExport();
      }
    },
    updateRule(args) {
      const predicate = this.$refs.correspondenceQueryBuilder.ej2Instances.getPredicate(args.rule);
      let dataManagerQuery;

      if (isNullOrUndefined(predicate)) {
        this.gridDataSource = this.prepareDataSource;
      } else {
        dataManagerQuery = new Query()
            .select(['Title', "Type", "ReportType", "Creator", "Date", "Company"])
            .where(predicate);

        this.gridDataSource = new DataManager(this.prepareDataSource)
            .executeLocal(dataManagerQuery);
      }

      this.$refs.grid.ej2Instances.refresh();
    },
    onGridCreated() {
      this.updateRule({ rule: this.$refs.correspondenceQueryBuilder.ej2Instances.getValidRules(this.$refs.correspondenceQueryBuilder.ej2Instances.rule) });
    },
  },
  provide: {
    grid: [Page, Selection, Sort,Toolbar, ExcelExport, Filter]
  },
  mounted(){
    this.createGridDataSource();
    if(!this.closedLeads)this.getClosedLeads();
    if(!this.closedProjects)this.getClosedProjects();
  }
}
</script>

<style scoped>

</style>