<template>
  <div class="flex justify-content-between gap-2">
    <div class="w-full">
      <SearchableSelectRow :show-table="true"
                           :show-timespan="showTimespan"
                           :search-option="selectedCategory"
                           :placeholder ="$t('placeholder.search')"
                           @selection-changed="goToDashboard" />
    </div>
    <div>
      <Dropdown v-model="selectedCategory" :options="searchableCategories"
                :placeholder="this.$t('placeholder.select')" />
    </div>
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";
import SearchableSelectRow from "@/components/global-components/searchabel-select-row/SearchableSelectRow";
export default {
  name: "NavbarSearchbar",
  components:{SearchableSelectRow, Dropdown},
  emits: ['closePopup','editLead'],
  data(){
    return{
      filterValue:"",
      selectedCategory:"",
    }
  },
  computed:{
    searchableCategories(){
      return [
        this.$t('leads'),
        this.$t('closedLeads'),
        this.$t('projects'),
        this.$t('closedProjects'),
        this.$t('company'),
        this.$t('person')
      ];
    },

    showTimespan(){
      return this.selectedCategory === this.$t('leads') || this.selectedCategory === this.$t('closedLeads') || this.selectedCategory === this.$t('projects') || this.selectedCategory === this.$t('closedProjects')
    }
  },
  methods:{
    /**
     * Goes to the dashboard of project, company or person.
     * If we clicked on a lead, we open the lead to edit / reopen.
     * @param data
     */
    goToDashboard(data){
      if(data.Type){
        if(data.Type === "Project"){
          this.$router.push({
            path:"/projects/dashboard/" + data.Id,
            params:data.Id
          });
          this.emitClosePopup();
        }else if(data.Type === 'Leads'){
          this.emitEditLead(data);
          this.emitClosePopup();
        }else if (data.Type === 'Contact'){
          this.$router.push({
            path: "/persons/contactDashboard/" + data.Id,
            params: data.Id
          });
          this.emitClosePopup();
        }else{
          this.$router.push({
            path:"/persons/dashboard/" + data.Id,
            params:data.Id
          });
          this.emitClosePopup();
        }
      }else{
        this.$router.push({
          path:"/companies/dashboard/" + data.Id,
          params:data.Id
        });
        this.emitClosePopup();
      }
    },

    emitClosePopup(){
      this.$emit('close-popup');
    },

    emitEditLead(data){
      this.$emit('edit-lead',data);
    }
  }
}
</script>

<style scoped>
.dropdown{
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  max-height: 197px;
  overflow: auto;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
}

.innerWrapperDropdown{
  padding: 15px;
  border-bottom: 1px solid lightgrey;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.innerWrapperDropdown:hover {
  background-color: lightgrey;
  cursor: pointer;
  border-radius: 2px;
}
</style>