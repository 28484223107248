<template>
  <div v-if="!loading" class="control-section" style="min-height: 500px">
    <TabView v-model:activeIndex="activeTab">
      <TabPanel :header="this.$t('projects') + ' (...)'">
        <ProjectQueryTable/>
      </TabPanel>
      <TabPanel :header="this.$t('leads') + ' (...)'">
        <LeadQueryTable />
      </TabPanel>
      <TabPanel :header="this.$t('companies') + ' (...)'">
        <CompanyQueryTable />
      </TabPanel>
      <TabPanel :header="this.$t('persons') + ' (...)'">
        <PersonQueryTable />
      </TabPanel>
      <TabPanel :header="this.$t('offers') + ' (...)'">
        <OfferQueryTable />
      </TabPanel>
      <TabPanel :header="this.$t('sales') + ' (...)'">
        <SalesQueryTable />
      </TabPanel>
      <TabPanel :header="this.$t('reports') + ' (...)'">
        <CorrespondenceQueryTable />
      </TabPanel>
    </TabView>
  </div>
</template>

<script>

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import ProjectQueryTable from "@/components/projects/project-query-table/ProjectQueryTable";
import LeadQueryTable from "@/components/leads/lead-query-table/LeadQueryTable";
import PersonQueryTable from "@/components/persons/person-query-table/PersonQueryTable";
import CompanyQueryTable from "@/components/company/company-query-table/CompanyQueryTable";
import SalesQueryTable from "@/components/sales/sales-query-table/SalesQueryTable";
import OfferQueryTable from "@/components/offers/offer-query-table/OfferQueryTable";
import CorrespondenceQueryTable from "@/components/correspondence/correspondence-query-table/CorrespondenceQueryTable";

export default {
  name: "QueryBuilder",
  components: {
    CorrespondenceQueryTable,
    SalesQueryTable, OfferQueryTable,
    LeadQueryTable, ProjectQueryTable, PersonQueryTable, CompanyQueryTable, TabPanel, TabView},
  data(){
    return {
      activeTab: 0,
    }
  },
  computed:{
    loading(){
      return this.$store.getters.loading;
    },
  },
}
</script>

<style scoped>

</style>