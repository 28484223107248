<template>
  <div class="outer-flex outer-margin">
    <CustomLabel class="inner-flex" :label="this.$t('title')" :input="projectData.Title">
      <InputText class="w-full" id="Title" v-model="projectData.Title" :class="v$.projectData.Title.$error ? 'p-invalid' : ''"/>
    </CustomLabel>

    <CustomLabel class="inner-flex" :label="this.$t('responsible')" :input="projectData.Responsible.Name">
      <Dropdown class="w-full" v-model="projectData.Responsible" :options="responsibleOptions" optionLabel="Name" filter autoFilterFocus
                :placeholder="this.$t('placeholder.responsible')" :class="v$.projectData.Responsible.$error ? 'p-invalid' : ''" />
    </CustomLabel>
  </div>
  <div class="outer-margin outer-flex">
    <CustomLabel class="inner-flex" :label="this.$t('company')" :input="projectData.Company.Name">
      <Dropdown class="w-full" v-model="projectData.Company" :options="companyOptions" :optionLabel="getCompanyOptionLabel" filter autoFilterFocus
                :placeholder="this.$t('placeholder.company')" :class="v$.projectData.Company.$error ? 'p-invalid' : ''"
                @update:modelValue="setProjectCompany" @filter="searchForCompanies"/>
    </CustomLabel>

    <CustomLabel class="inner-flex" label="Phase" :input="projectData.Phase">
      <Dropdown class="w-full" v-model="projectData.Phase" :options="phaseOptions" optionLabel="Name"
                placeholder="Phase" :class="v$.projectData.Phase.$error ? 'p-invalid' : ''" />
    </CustomLabel>
  </div>

  <div v-if="projectData.Company" class="mb-2 mt-2">
    <div class="flex justify-content-between gap-2 mb-2">
      <CustomLabel class="w-full" :label="$t('location')" :input="projectData.Location ? projectData.Location.LocationName : ''">
        <Dropdown class="w-full" v-model="projectData.Location" :options="locationOptions" optionLabel="LocationName"
                  :placeholder="$t('placeholder.location')" />
      </CustomLabel>

      <CustomLabel class="w-full" :label="$t('supplier')" :input="projectData.Supplier">
        <Dropdown class="w-full" v-model="projectData.Supplier" :options="supplierOptions"
                  :placeholder="$t('placeholder.supplier')" />
      </CustomLabel>
    </div>

    <ContactSection :company-id="projectData.Company.Id"
                    :contact-props="projectData.Contact"
                    :contact-options="contactOptions"
                    :is-dropdown="true"
                    :show-load-button="true"
                    @contact-changed="setContact"
                    @get-contacts="getContactsOptions"/>
  </div>

  <div class="flex justify-content-between gap-2 mb-2 mt-2">
    <CustomLabel class="w-full" :label="this.$t('members')" :input="projectData.Members[0] ? projectData.Members[0].Name : ''">
      <MultiSelect class="w-full" v-model="projectData.Members" :options="membersOptions" optionLabel="Name" filter
                   :placeholder="this.$t('placeholder.selectMembers')" autoFilterFocus/>
    </CustomLabel>

    <CustomLabel class="w-full" :label="this.$t('budget')" :input="projectData.Budget">
      <InputNumber class="w-full" v-model="projectData.Budget" :min="0" :placeholder="this.$t('placeholder.budget')" />
    </CustomLabel>
  </div>

  <div class="outer-margin">
    <CustomLabel :label="this.$t('description')" :input="projectData.Description">
      <Editor class="w-full"
              editorStyle="height: 320px"
              v-model="projectData.Description"
              id="Description"
              :placeholder="this.$t('placeholder.description')"> 
        <template v-slot:toolbar>
          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
          </span>
          <span class="ql-formats">
            <select class="ql-color"></select>
            <select class="ql-background"></select>
          </span>
          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-list" value="check"></button>
          </span>
          <span class="ql-formats">
            <button class="ql-clean"></button>
          </span>
        </template>
      </Editor>
    </CustomLabel>
  </div>

  <div class="flex align-items-center gap-1">
    <InputSwitch v-model="hasEquipment" />
    <span v-if="hasEquipment">{{this.$t('hasEquipments')}}</span>
    <span v-else>{{this.$t('hasNoEquipments')}}</span>
  </div>

  <div v-if="hasEquipment" class="flex justify-content-between gap-2 mb-2 mt-2">
    <CustomLabel class="w-full" :label="this.$t('equipments')" :input="projectData.Equipment[0] ? projectData.Equipment[0].Name : ''">
      <MultiSelect class="w-full" v-model="projectData.Equipment" :options="equipmentOptions" optionLabel="Name" filter
                   :placeholder="this.$t('placeholder.equipments')" autoFilterFocus/>
    </CustomLabel>
  </div>

  <div class="mt-4 mb-4 border-bottom-1"/>

  <div class="mb-6 mt-2">
    <div class="mb-2">Tags</div>
    <div>
      <div class="w-full mb-2">
        <CustomLabel class="w-full" :label="this.$t('classification')" :input="selectedTagContainer">
          <Dropdown class="w-full" v-model="selectedTagContainer" :options="tagContainers" optionLabel="ContainerName"
                    :class="v$.selectedTagContainer.$error ? 'p-invalid' : ''" :placeholder="this.$t('classification')"
                    :disabled="projectData.ProjectTagContainer ? !!projectData.ProjectTagContainer.id : false" />
        </CustomLabel>
      </div>

      <div v-for="(customList) of customLists" class="flex justify-content-between gap-2 mb-2 mt-2" :key="customList.Id">
        <div v-if="customList && customList.FieldParams.IsInputField" class="w-full" >
          <CustomLabel class="w-full" :label="$i18n.locale === 'de' ? customList.ClusterName : customList.ClusterNameEn" :input="projectData.ProjectTags[customList.Id]">
            <InputText class="w-full" v-model="projectData.ProjectTags[customList.Id]"
                       :class="!customList.isOptional ? v$.projectData.ProjectTags[customList.Id].$error ? 'p-invalid' : '' : ''"
                       :placeholder="$i18n.locale === 'de' ? customList.ClusterName : customList.ClusterNameEn"/>
          </CustomLabel>
        </div>
        <div v-else-if="customList && customList.HasMultipleOptions" class="w-full">
          <CustomLabel class="w-full" :label="$i18n.locale === 'de' ? customList.ClusterName : customList.ClusterNameEn" :input="projectData.ProjectTags[customList.Id]">
            <MultiSelect class="w-full" :options="customList.TagNames" filter autoFilterFocus  v-model="projectData.ProjectTags[customList.Id]"
                         :class="!customList.isOptional ? v$.projectData.ProjectTags[customList.Id].$error ? 'p-invalid' : '' : ''"
                         :placeholder="$i18n.locale === 'de' ? customList.ClusterName : customList.ClusterNameEn" />
          </CustomLabel>
        </div>
        <div v-else-if="customList" class="w-full">
          <CustomLabel class="w-full" :label="$i18n.locale === 'de' ? customList.ClusterName : customList.ClusterNameEn" :input="projectData.ProjectTags[customList.Id]">
            <Dropdown class="w-full" v-model="projectData.ProjectTags[customList.Id]" :options="customList.TagNames"
                      :class="!customList.isOptional ? v$.projectData.ProjectTags[customList.Id].$error ? 'p-invalid' : '' : ''"
                      :placeholder="$i18n.locale === 'de' ? customList.ClusterName : customList.ClusterNameEn" />
          </CustomLabel>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import CustomLabel from "@/components/global-components/custom-label/CustomLabel";
import ContactSection from "@/components/global-components/contact-section/ContactSection";
import globalComputedProperties from "@/mixins/global-computed-properties/global-computed-properties";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Editor from "primevue/editor";
import MultiSelect from "primevue/multiselect";
import InputSwitch from "primevue/inputswitch";
import InputNumber from "primevue/inputnumber";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import uuidv4Generator from "@/mixins/uuidv4/Uuidv4Generator";

export default {
  name: "EditProject",
  components:{
    CustomLabel, ContactSection, InputText, Dropdown, Editor, MultiSelect, InputSwitch, InputNumber,
  },
  emits: ['closePopup'],
  props:{
    projectDataProp:Object,
  },
  mixins:[globalComputedProperties, uuidv4Generator],
  data(){
    return {
      hasEquipment:false,
      projectData: JSON.parse(JSON.stringify(this.projectDataProp)),
      selectedTagContainer:null,
      companyOptions:[],
      locationOptions:[],
      contactOptions:[],
      supplierOptions: [],
    }
  },

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  /**
   * Validates my projectData keys + the selectedTagContainer variable.
   * We go through each entry of the customLists array and check,
   * if the customList is required. If it is, we add the customListId
   * into the LeadTags and set is as required.
   */
  validations(){
    const validations = {
      projectData: {
        Title:{required},
        Responsible: {required},
        Company: {required},
        Phase: {required},
      },
      selectedTagContainer:{required},
    };
    // Dynamically define validation rules for each item in customLists
    this.customLists.forEach(customList => {
      validations.projectData.ProjectTags = validations.projectData.ProjectTags || {};
      if(!customList.isOptional){
        validations.projectData.ProjectTags[customList.Id] = { required };
      }
    });

    return validations
  },

  computed:{
    responsibleOptions(){
      return this.$store.getters.responsibles.map(responsible => ({Name:responsible.FriendlyName,Id:responsible.User, type: "User"}));
    },

    /**
     * We iterate through the contacts and responsibles and map them into our members array with only
     * keys that we need for our members. After that we sort the array.
     * @returns {({Id: *, type: string, Name: *}|{Id: *, type: string, Name: *})[]}
     */
    membersOptions(){
      let members = [];
      members = [
        ...this.contactOptions
            .map(contact => ({ Name: contact.Name, Id: contact.Id, type: "Contact" })),

        ...this.$store.getters.responsibles
            .map(user => ({ Name: user.FriendlyName, Id: user.User, type: "User" })),
      ];

      return members.sort((a,b) => {
        if (a['Name'] < b['Name']) return -1;
        if (a['Name'] > b['Name']) return 1;
      });
    },

    /**
     * First we map all equipments to only contain the Name & Id keys.
     * If our projectData has a Company selected, we filter through all equipments and only return equipments,
     * that match the selected company, otherwise we return all equipments.
     * @returns {{Id: *, Name: *}[]}
     */
    equipmentOptions(){
      const equipments = this.$store.getters.equipments;
      let changedEquipmentData = equipments.map(equipment => ({Name: equipment.EquipmentName, Id: equipment.Id}));

      if (this.projectData.Company) {
        changedEquipmentData = equipments.filter(equipment => equipment.CompanyId === this.projectData.Company.Id)
            .map(equipment => ({Name: equipment.EquipmentName, Id: equipment.Id}));
      }
      return changedEquipmentData;
    },

    phaseOptions(){
      return this.$store.getters.projectPhases;
    },

    /**
     * We only return tagContainers where the Coverage equals Projects.
     */
    tagContainers(){
      return this.$store.getters.tagContainers.filter(tagContainer => tagContainer.Coverage === "Projects");
    },

    customLists(){
      const filteredCustomList = [];
      const customLists = this.$store.getters.customLists;
      if(this.selectedTagContainer){
        for(let customList of customLists){
          for(let tag of this.selectedTagContainer.Tags){
            if(tag === customList.Id){
              filteredCustomList.push(customList);
            }
          }
        }
      }
      return filteredCustomList;
    },
  },
  methods:{
    getContactsOptions(){
      this.getContactsByCompanyId(this.projectData.Company.Id);
    },

    searchForCompanies(event){
      this.$store.commit('setLoading',false);

      clearTimeout(this.searchForCompanies.timeoutId);

      if (event.value.length > 2) {
        this.searchForCompanies.timeoutId = setTimeout(() => {
          this.$store.commit('setLoading',true);
          this.companyOptions = [];
          this.$store.dispatch("getRequest","getCompaniesBySearchValue&query=" + event.value).then(resp => {
            this.$store.commit('setLoading',false);
            if(resp && resp.statusCode === 200){
              this.companyOptions = JSON.parse(resp.body);
            }
          });
        }, 1000);
      }
    },

    setProjectCompany(event){
      const company = {Name:event.Name,Id:event.Id,CompanyNumber:event.CompanyNumber};
      this.projectData.Company = company;
      const foundCompanyIndex = this.companyOptions.findIndex(filteredCompany => filteredCompany.Id === company.Id);
      if(foundCompanyIndex !== -1){
        this.companyOptions[foundCompanyIndex] = company;
      }
      this.getContactsByCompanyId(event.Id);
      this.getSupplierOptions(event.Tags);
      this.locationOptions = event.GoodsReceivers ? event.GoodsReceivers : [];
      this.projectData.Equipment = [];
      this.projectData.Location = {};
      this.projectData.Contact = {Id:"",Name:"",Email:"",Phone:"",Landline:""};
    },

    getContactsByCompanyId(companyId){
      this.$store.commit('setLoading',true);
      this.$store.dispatch("getRequest","getContactsByCompanyId&query=" + companyId).then(resp => {
        this.$store.commit('setLoading',false);
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.contactOptions = body.map(contact => ({ Name: contact.ContactName,
            Id:contact.ContactId,
            Email:contact.ContactEmail,
            Phone:contact.OptionalParams ? contact.OptionalParams.Mobile ? contact.OptionalParams.Mobile : "" : "",
            Landline:contact.OptionalParams ? contact.OptionalParams.Landline ? contact.OptionalParams.Landline : "" : ""}));
        }
      });
    },

    getSupplierOptions(tags){
      if(tags && tags.length > 0){
        const foundSupplierTag = tags.find((tag) => tag.labelName === 'Lieferwerkzuordnung');
        if(foundSupplierTag) this.supplierOptions = foundSupplierTag.selectedValue;
      }
    },

    getLocationsByCompanyId(companyId){
      this.$store.dispatch("getRequest","getGoodsReceiversByCompanyId&query=" + companyId).then(resp => {
        this.$store.commit('setLoading',false);
        if(resp && resp.statusCode === 200){
          this.locationOptions = JSON.parse(resp.body);
        }
      });
    },

    getTagsByCompanyId(){
      this.$store.dispatch("getRequest","getTagsByCompanyId&query=" + this.projectData.Company.Id).then(resp => {
        this.$store.commit('setLoading',false);
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.getSupplierOptions(body);
        }
      });
    },

    getCompanyOptionLabel(props){
      return props.Name + " (" + props.CompanyNumber + ")";
    },

    setContact(event){
      this.projectData.Contact = event;
      const foundContactInMembers = this.projectData.Members.find(member => member.Id === event.Id);
      if(!foundContactInMembers){
        this.projectData.Members.push({Id:event.Id,Name:event.Name,type:"Contact"});
      }
    },

    /**
     * If we have a selectedTagContainer we set the ProjectTagContainer key &
     * if we have a selectedCustomList, we set the ProjectTags key.
     * In the end we emit to submit the edit project into the ProjectDashboard.
     */
    submitEdit(){
      this.v$.$validate();
      if(!this.v$.$error){
        if(!this.hasEquipment) this.projectData.Equipment = [];

        this.projectData.ProjectTagContainer = this.selectedTagContainer;

        this.customLists.forEach(customList => {
          const foundTag = Object.keys(this.projectData.ProjectTags).filter(tag => tag === customList.Id)[0];
          if(customList.isOptional && !foundTag){
            this.projectData.ProjectTags[customList.Id] = "";
          }
        });

        if(this.projectData.Contact && this.projectData.Contact.Id){
          this.projectData.ContactId = this.projectData.Contact.Id;
        }
        this.projectData.CompanyId = this.projectData.Company.Id;
        this.projectData.ResponsibleId = this.projectData.Responsible.Id;

        this.projectData.LastEdited = new Date().getTime();

        const changedData = this.checkForProjectChanges(this.projectData);
        const changelogs = this.setChangelogs(changedData);

        if(changelogs){
          this.projectData.Changelogs = [...this.projectData.Changelogs, changelogs];
          this.$store.dispatch('updateChangelog',this.projectData).then(() => {
            this.closePopup();
          });
        }else{
          this.$store.dispatch('editProject',this.projectData).then(() => {
            this.closePopup();
          });
        }
      }else{
        this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('errorKeysNeeded') + this.getErrors(), life: 5000 });
      }
    },

    closePopup(){
      this.$emit('close-popup');
      this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.projectEdited'), life: 5000 });
    },

    /**
     * Here we check for all changes possible for editing the project and write the Text for the changelog.
     * @param editedProject
     * @returns {{Company: string, Phase: string, Description: string, Title: string, Responsible: string}}
     */
    checkForProjectChanges(editedProject){
      let changedData = {Title:"",Company:"",Responsible:"",Phase:"",Description:"",Equipment: "",Members:"",Contact:"",Budget:""};

      if(editedProject.Title !== this.projectDataProp.Title) changedData.Title = this.$t('title') + ": " + this.projectDataProp.Title + "->" + this.projectData.Title;
      if(editedProject.Company.Id !== this.projectDataProp.Company.Id) changedData.Company = this.$t('company') + ": " + this.projectDataProp.Company.Name + " -> " + this.projectData.Company.Name;
      if(editedProject.Responsible.Id !== this.projectDataProp.Responsible.Id) changedData.Responsible = this.$t('responsible') + ": " + this.projectDataProp.Responsible.Name + " -> " + this.projectData.Responsible.Name;
      if(editedProject.Phase.Number !== this.projectDataProp.Phase.Number) changedData.Phase = "Phase: " + this.projectDataProp.Phase.Name + " -> " + this.projectData.Phase.Name;
      if(editedProject.Description !== this.projectDataProp.Description) changedData.Description = this.$t('description') + ": " + this.projectDataProp.Description + " -> " + this.projectData.Description;
      if(this.differentEquipments(editedProject)) changedData.Equipment = "Equipment: " + this.getProjectEquipments() + " -> " + this.getEditedEquipments(this.projectData.Equipment);
      if(this.differentMembers(editedProject)) changedData.Members = this.$t('members') + ": " + this.getProjectMembers() + " -> " + this.getEditedMembers(this.projectData.Members);
      if(editedProject.Contact.Id !== this.projectDataProp.Contact.Id) changedData.Contact = this.$t('contact') + ": " + this.projectDataProp.Contact.Name + " -> " + this.projectData.Contact.Name;
      if(editedProject.Budget !== this.projectDataProp.Budget) changedData.Budget = this.$t('budget') + ": " + this.projectDataProp.Budget + " -> " + this.projectData.Budget;
      return changedData;
    },

    /**
     * Here we see if the current Equipments for this project are all the same,
     * or if we edited the Equipments and added or removed equipments.
     * If the editedEquipment length is the same as count that means there are no edits,
     * otherwise we have an edit and return true.
     * @param editedProject
     * @returns {boolean}
     */
    differentEquipments(editedProject){
      let count = 0;
      for(let equipment of this.projectDataProp.Equipment){
        for(let editedEquipment of editedProject.Equipment){
          if(editedEquipment.Id === equipment.Id){
            count++;
            break;
          }
        }
      }
      if(this.projectDataProp.Equipment.length > editedProject.Equipment.length) return true;
      return count !== editedProject.Equipment.length;
    },

    /**
     * Here we see if the current members for this project are all the same,
     * or if we edited the Members and added or removed members.
     * If the editedMembers length is the same as count that means there are no edits,
     * otherwise we have an edit and return true.
     * @param editedProject
     * @returns {boolean}
     */
    differentMembers(editedProject){
      let count = 0;
      for(let member of this.projectDataProp.Members){
        for(let editedMember of editedProject.Members){
          if(editedMember.Id === member.Id){
            count++;
            break;
          }
        }
      }
      if(this.projectDataProp.Members.length > editedProject.Members.length) return true;
      return count !== editedProject.Members.length;
    },

    /**
     * We get all equipment names that the project previously had
     * and return them into the changelog.
     * @returns {string}
     */
    getProjectEquipments(){
      let data = [];
      for (let i = 0; i < this.projectDataProp.Equipment.length; i++){
        data.push(this.projectDataProp.Equipment[i].Name);
      }

      return data.toString();
    },

    /**
     * We get all member names that the project previously had
     * and return them into the changelog.
     * @returns {string}
     */
    getProjectMembers(){
      let data = [];
      for (let i = 0; i < this.projectDataProp.Members.length; i++){
        data.push(this.projectDataProp.Members[i].Name);
      }

      return data.toString();
    },

    /**
     * We get all equipment names that were changed from the previously selected
     * and return them into the changelog.
     * @param equipment
     * @returns {string}
     */
    getEditedEquipments(equipment){
      let data = [];
      for (let i = 0; i < equipment.length; i++){
        data.push(equipment[i].Name);
      }

      return data.toString();
    },

    /**
     * We get all member names that were changed from the previously selected
     * and return them into the changelog.
     * @param members
     * @returns {string}
     */
    getEditedMembers(members){
      let data = [];
      for (let i = 0; i < members.length; i++){
        data.push(members[i].Name);
      }

      return data.toString();
    },

    /**
     * Sets the Changelog with all changes into a text.
     * @param changedData
     * @returns {{Unix: number, Author: string, Text: string, Id: *}}
     */
    setChangelogs(changedData){
      let text = "";

      for(let change of Object.values(changedData)){
        if(change.length > 0){
          text = text + change + "\n";
        }
      }

      if(text.length > 0){
        return {
          "Id":this.createUuidv4(),
          "Author":this.username,
          "Text":this.username + " " + this.$t('followingChanges') + " " + text,
          "Unix":new Date().getTime(),
        }
      }else{
        return null;
      }

    },

    getErrors(){
      let errorList = "";
      for(let error of this.v$.$errors){
        if(errorList !== "" && !errorList.includes(this.$t('tags'))){
          errorList = errorList + ", ";
        }
        switch(error.$property){
          case "Title":
            errorList = errorList + ' ' + this.$t('title');
            break;
          case "Responsible":
            errorList = errorList + ' ' + this.$t('responsible');
            break;
          case "Company":
            errorList = errorList + ' ' + this.$t('company')
            break;
          case "Phase":
            errorList = errorList + ' ' + this.$t('placeholder.phase');
            break;
          case "Description":
            errorList = errorList + ' ' + this.$t('description');
            break;
          case "selectedTagContainer":
            errorList = errorList + ' ' + this.$t('classification');
            break;
          default:
            errorList = errorList + ' ' + this.$t('tags');
            break;
        }
      }

      return errorList;
    },
  },
  mounted() {
    this.hasEquipment = this.projectData.Equipment.length > 0;

    // Since companyOptions is empty at the beginning and we need to display the currently selected option,
    // will we push the current company from the project into the options array. If we search for
    // a new company, we would empty the companyOptions
    this.companyOptions.push(this.projectData.Company);

    this.getLocationsByCompanyId(this.projectData.Company.Id);
    this.getTagsByCompanyId();
    //Set container
    if(this.projectData.ProjectTagContainer){
      if(this.projectData.ProjectTagContainer.id){
        this.selectedTagContainer = this.projectData.ProjectTagContainer;
      }
    }
  },
}
</script>

<style scoped>

.outer-flex{
  display:flex;
  justify-content: space-between;
  gap:10px;
}

.inner-flex{
  flex:0.5;
}

.outer-margin{
  margin-top:10px;
  margin-bottom:10px;
}
</style>