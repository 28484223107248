<template>
  <div v-if="person" class="flex gap-2 h-full" style="overflow:hidden">
    <div class="flex flex-column flex-auto overflow-y-auto" :class="showDashboardViewer ? 'col-8' : 'col-12'">
      <div class="surface-section p-5" style="box-sizing:border-box;width:100%; margin-bottom:10px; position:sticky;top:0;background:#EEF2FF;z-index:10">
        <PersonDashboardHeader :person="person"
                               @show-cards="setShowCards"
                               :show-dashboard-viewer="showDashboardViewer"
                               @show-dashboard-viewer="setShowDashboardViewer"/>
      </div>

      <div class="grid">
        <PersonDashboardCards v-if="showCards"
                              :lead-count="leads.length"
                              :project-count="projects.length"
                              :task-count="person.Tasks.length"
                              :offer-count="person.Offers.length"
                              :sale-count="sales.length"
                              :correspondence-count="person.Correspondence ? person.Correspondence.length : 0"
                              @go-to-table-tab="setMaterialTableIndex"/>

        <div class="col-12" ref="materialTable">
          <PersonMaterialTable :leads="leads"
                               :projects="projects"
                               :tasks="person.Tasks"
                               :offers="person.Offers"
                               :person="person"
                               :sales="sales"
                               :active-index="materialTableIndex"
                               :correspondences="person.Correspondence"
                               :correspondences-length="person.Correspondence ? person.Correspondence.length : 0"
                               @load-more-data="loadMoreData"
                               :closed-leads="closedLeads"
                               :closed-projects="closedProjects"
                               :show-load-more-leads="leadsLastEvaluatedKey"
                               :show-load-more-projects="projectsLastEvaluatedKey"
                               :show-load-more-closed-leads="closedLeadsLastEvaluatedKey"
                               :show-load-more-closed-projects="closedProjectsLastEvaluatedKey"
                               :show-load-more-sales="salesLastEvaluatedKey"
                               @row-clicked="setDashboardViewerData" />
        </div>
      </div>
    </div>

    <div v-if="showDashboardViewer" class="col-4" style="max-height:75vh;overflow-y:auto;">
      <DashboardViewer :selected-data="selectedData"
                       :data-type="dataType"
                       :user-id="personId"
                       :correspondence-type="$t('person')" />
    </div>
  </div>
  <div v-else>
    <DashboardInitialLoader />
  </div>
</template>

<script>
import PersonDashboardHeader from "@/components/persons/person-dashboard-header/PersonDashboardHeader";
import PersonDashboardCards from "@/components/persons/person-dashboard-cards/PersonDashboardCards";
import PersonMaterialTable from "@/components/persons/person-material-table/PersonMaterialTable";
import DashboardViewer from "@/components/global-components/dashboard-viewer/DashboardViewer";
import DashboardInitialLoader from "@/components/global-components/dashboard-initial-loader/DashboardInitialLoader";

export default {
  name: "PersonDashboard",
  components: {
    DashboardViewer,
    PersonMaterialTable,
    PersonDashboardCards,
    PersonDashboardHeader,
    DashboardInitialLoader,
  },

  created() {
    // Getting the current person id, if we reload the site inside the dashboard.
    const routerParams = this.$router.currentRoute.value.params;
    this.personId = routerParams.Id;
  },

  data(){
    return{
      materialTableIndex:0,
      personId:"",
      showCards:true,
      showDashboardViewer:false,
      selectedData:null,
      dataType:"",
      leads:[],
      projects:[],
      closedLeads:[],
      closedProjects:[],
      leadsLastEvaluatedKey:null,
      projectsLastEvaluatedKey:null,
      closedLeadsLastEvaluatedKey:null,
      closedProjectsLastEvaluatedKey:null,
      sales:[],
      salesLastEvaluatedKey:null,
    }
  },
  computed:{
    person() {
      return this.$store.getters.userDashboard;
    },
  },
  methods:{
    setShowDashboardViewer(trueOrFalse){
      this.showDashboardViewer = trueOrFalse;
      this.selectedData = null;
      this.dataType = "";
    },

    setDashboardViewerData(data){
      if(this.showDashboardViewer){
        this.selectedData = data.data;
        this.dataType = data.type;
      }else{
        if(data.type === 'Project'){
          this.$router.push({
            path:"/projects/dashboard/" + data.data.Id,
            params:data.data.Id
          });
        }else if(data.type === 'Task' || data.type === 'Offer' || data.type === 'Sale'){
          this.$router.push({
            path:"/projects/dashboard/" + data.data.ProjectId,
            params:data.data.Id
          });
        }else{
          this.showDashboardViewer = true;
          this.selectedData = data.data;
          this.dataType = data.type;
        }
      }
    },

    setMaterialTableIndex(index){
      this.materialTableIndex = index;
      const scrollContainer = this.$refs.materialTable;
      scrollContainer.scrollIntoView({
        behavior: 'smooth',
        block:'start'
      });
    },

    setShowCards(trueOrFalse){
      this.showCards = trueOrFalse;
    },

    loadMoreData(param){
      switch(param){
        case 'lead':
          this.getMoreLeads();
          break;
        case 'closedLead':
          this.getMoreClosedLeads();
          break;
        case 'project':
          this.getMoreProjects();
          break;
        case 'closedProject':
          this.getMoreClosedProjects();
          break;
        case 'sale':
          this.getMoreSales();
          break;
      }
    },

    getMoreLeads(){
      const id = this.leadsLastEvaluatedKey.Id;
      const created = this.leadsLastEvaluatedKey.Created;
      const userId = this.leadsLastEvaluatedKey.UserId;
      this.$store.dispatch("getRequest","getMoreLeadsByUser&query=" + [this.personId, id, created, userId]).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.leads = [...this.leads, ...body.Items];

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.leadsLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });
    },

    getMoreClosedLeads(){
      const id = this.closedLeadsLastEvaluatedKey.Id;
      const created = this.closedLeadsLastEvaluatedKey.Created;
      const userId = this.closedLeadsLastEvaluatedKey.UserId
      this.$store.dispatch("getRequest","getMoreClosedLeadsByUser&query=" + [this.personId, id, created, userId]).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.closedLeads = [...this.closedLeads, ...body.Items];

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.closedLeadsLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });
    },

    getMoreProjects(){
      const id = this.projectsLastEvaluatedKey.Id;
      const created = this.projectsLastEvaluatedKey.Created;
      const userId = this.projectsLastEvaluatedKey.UserId;
      this.$store.dispatch("getRequest","getMoreProjectsByUser&query=" + [this.personId, id, created, userId]).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.projects = [...this.projects, ...body.Items];

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.projectsLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });
    },

    getMoreClosedProjects(){
      const id = this.closedProjectsLastEvaluatedKey.Id;
      const created = this.closedProjectsLastEvaluatedKey.Created;
      const userId = this.closedProjectsLastEvaluatedKey.UserId;
      this.$store.dispatch("getRequest","getMoreClosedProjectsByUser&query=" + [this.personId, id, created, userId]).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.closedProjects = [...this.closedProjects, ...body.Items];

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.closedProjectsLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });
    },

    getMoreSales(){
      const id = this.salesLastEvaluatedKey.Id;
      const salesDate = this.salesLastEvaluatedKey.SalesDate;
      const userId = this.salesLastEvaluatedKey.UserId;
      this.$store.dispatch("getRequest","getMoreSalesByUser&query=" + [this.personId, id, salesDate, userId]).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.sales = [...this.sales, ...body.Items];

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.salesLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });
    },

    getPersonById(){
      this.$store.dispatch("getRequest","getUserById&query=" + this.personId).then(resp => {
        if(resp && resp.statusCode === 200){
          const person = JSON.parse(resp.body);
          this.$store.commit('setUserDashboard',person);
          this.$store.commit('setShouldReloadDashboard', false);
          localStorage.setItem('lastClickedPersonDashboard',JSON.stringify({
            Id:person.User,
            Name:person.FriendlyName,
            Type:'User',
          }));
        }
      });
    },

    getData(){
      this.$store.dispatch("getRequest","getLeadsByUser&query=" + this.personId).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.leads = body.Items;

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.leadsLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });

      this.$store.dispatch("getRequest","getProjectsByUser&query=" + this.personId).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);

          this.projects = body.Items;

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.projectsLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });

      this.$store.dispatch("getRequest","getSalesByUser&query=" + this.personId).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);

          this.sales = body.Items;

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.salesLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });
    },

    getClosedData(){
      this.$store.dispatch("getRequest","getClosedLeadsByUser&query=" + this.personId).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.closedLeads = body.Items;

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.closedLeadsLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });

      this.$store.dispatch("getRequest","getClosedProjectsByUser&query=" + this.personId).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.closedProjects = body.Items;

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.closedProjectsLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });
    }
  },
  mounted(){
    this.getPersonById();
    this.getData();
    this.getClosedData();
  },
  watch:{
    //In case we switch routes by using the search function with the magnifying glass icon/button,
    //we need to set the new personId and get the new user data.
    $route(to){
      if(to.params.Id){
        if(this.personId !== to.params.Id){
          this.personId = to.params.Id;
          this.getPersonById();
        }
      }
    },

    '$store.getters.shouldReloadDashboard': {
      handler() {
        // Fetch the data again when the flag changes
        if(this.$store.getters.shouldReloadDashboard){
          this.getPersonById();
          this.getData();
          this.getClosedData();
        } 
      },
    },
  },
  unmounted() {
    this.$store.commit('setUserDashboard',null);
  }
}
</script>

<style scoped>
:deep(.p-tabview .p-tabview-panels){
  padding-left:0;
  padding-right:0;
}
</style>