<template>
  <div v-if="company" class="flex gap-2 h-full" style="overflow:hidden">
    <div class="flex flex-column flex-auto overflow-y-auto" :class="showDashboardViewer ? 'col-8' : 'col-12'">
      <div class="surface-section p-5" style="box-sizing:border-box;width:100%; margin-bottom:10px; position:sticky;top:0;background:#EEF2FF;z-index:10">
        <CompanyDashboardHeader :company="company"
                                :last-contact="lastContact"
                                @show-cards="setShowCards"
                                :show-dashboard-viewer="showDashboardViewer"
                                @show-dashboard-viewer="setShowDashboardViewer" />
      </div>

      <div class="grid">
        <div class="col-12 overflow-x-auto" ref="materialTable">
          <CompanyMaterialTable :company="company"
                                :company-locations="company.GoodsReceivers ? company.GoodsReceivers : []"
                                :company-users="companyUsers"
                                :active-index="materialTableIndex"
                                :data-with-correspondence="dataWithCorrespondence"
                                :report-count="reportCount"
                                :correspondences="company.Correspondence"
                                :company-equipments="companyEquipments"
                                :leads="leads"
                                :projects="projects"
                                :closed-leads="closedLeads"
                                :closed-projects="closedProjects"
                                :sales="sales"
                                :show-load-more-leads="leadsLastEvaluatedKey"
                                :show-load-more-projects="projectsLastEvaluatedKey"
                                :show-load-more-closed-leads="closedLeadsLastEvaluatedKey"
                                :show-load-more-closed-projects="closedProjectsLastEvaluatedKey"
                                :show-load-more-sales="salesLastEvaluatedKey"
                                @load-more-data="loadMoreData"
                                @go-to-table-tab="setMaterialTabIndex"
                                @row-clicked="setDashboardViewerData" />
        </div>
      </div>
    </div>

    <div v-if="showDashboardViewer" class="col-4" style="max-height:75vh;overflow-y:auto;">
      <DashboardViewer :selected-data="selectedData" :data-type="dataType" :company-id="companyId" :correspondence-type="$t('company')" />
    </div>
  </div>
  <div v-else>
    <DashboardInitialLoader />
  </div>
</template>

<script>
import CompanyDashboardHeader from "@/components/company/company-dashboard-header/CompanyDashboardHeader";
// import CompanyDashboardCards from "@/components/company/company-dashboard-cards/CompanyDashboardCards";
import CompanyMaterialTable from "@/components/company/company-material-table/CompanyMaterialTable";
import DashboardViewer from "@/components/global-components/dashboard-viewer/DashboardViewer";
import DashboardInitialLoader from "@/components/global-components/dashboard-initial-loader/DashboardInitialLoader";

export default {
  name: "CompanyDashboard",
  components: {
    DashboardViewer,
    CompanyMaterialTable, 
    // CompanyDashboardCards,
    CompanyDashboardHeader,
    DashboardInitialLoader,
  },

  created() {
    // Getting the current company id, if we reload the site inside the dashboard.
    const routerParams = this.$router.currentRoute.value.params;
    this.companyId = routerParams.Id;
  },

  data(){
    return{
      companyId:"",
      contacts:[],
      materialTableIndex:0,
      users:[],
      companyUsers:[],
      showCards:true,
      lastContact:null,
      reportCount:0,
      showDashboardViewer:false,
      selectedData:null,
      dataType:"",
      leads:[],
      projects:[],
      leadsLastEvaluatedKey:null,
      projectsLastEvaluatedKey:null,
      closedLeads:[],
      closedProjects:[],
      closedLeadsLastEvaluatedKey:null,
      closedProjectsLastEvaluatedKey:null,
      sales:[],
      salesLastEvaluatedKey:null,
    }
  },
  computed:{
    company(){
      return this.$store.getters.companyDashboard;
    },

    companyEquipments(){
      const equipments = [];
      for(let equipment of this.$store.getters.equipments){
        if(equipment.CompanyId === this.companyId){
          equipments.push(equipment);
        }
      }
      return equipments;
    },

    dataWithCorrespondence(){
      const leads = this.$store.getters.leads;
      const projects = this.$store.getters.projects;
      let src = [];
      let data = [...leads, ...projects];
      if(this.closedLeads && this.closedProjects){
        data = [...data, ...this.closedLeads, ...this.closedProjects];
      }

      data = data.filter(object => object.Company.Id === this.companyId);

      for(let object of data){
        const foundObject = src.find(currentObject => currentObject.Id === object.Id);
        if(!foundObject){
          if(object.Correspondence && object.Correspondence.length > 0){
            src.push(object);
          }
        }
      }
      return src;
    },
  },
  methods:{
    setShowCards(trueOrFalse){
      this.showCards = trueOrFalse;
    },

    setShowDashboardViewer(trueOrFalse){
      this.showDashboardViewer = trueOrFalse;
      this.selectedData = null;
      this.dataType = "";
    },

    setDashboardViewerData(data){
      if(this.showDashboardViewer){
        this.selectedData = data.data;
        this.dataType = data.type;
      }else{
        if(data.type === 'Project'){
          this.$router.push({
            path:"/projects/dashboard/" + data.data.Id,
            params:data.data.Id
          });
        }else if(data.type === 'Task' || data.type === 'Offer' || data.type === 'Sale'){
          this.$router.push({
            path:"/projects/dashboard/" + data.data.ProjectId,
            params:data.data.Id
          });
        }else{
          this.showDashboardViewer = true;
          this.selectedData = data.data;
          this.dataType = data.type;
        }
      }
    },

    setCompanyUsers(persons){
      const people = [];
      for (let contact of persons.contacts) {
        people.push({
          Name: contact.ContactName,
          Id: contact.ContactId,
          Mobile: contact.OptionalParams.Mobile,
          Department: contact.OptionalParams && contact.OptionalParams.Department,
          Position: contact.OptionalParams && contact.OptionalParams.Position,
          Type: "Contact"
        });
      }
      for (let user of persons.users) {
        people.push({
          Name: user.FriendlyName,
          Id: user.User,
          Mobile: user.OptionalParams.Mobile,
          Department: user.OptionalParams && user.OptionalParams.Department,
          Position: user.OptionalParams && user.OptionalParams.Position,
          Type: "User"
        })
      }
      this.companyUsers = people.sort((a, b) => {
        if (a['Name'] < b['Name']) return -1;
        if (a['Name'] > b['Name']) return 1;
      });
    },

    loadMoreData(param){
      switch(param){
        case 'lead':
          this.getMoreLeads();
          break;
        case 'closedLead':
          this.getMoreClosedLeads();
          break;
        case 'project':
          this.getMoreProjects();
          break;
        case 'closedProject':
          this.getMoreClosedProjects();
          break;
        case 'sale':
          this.getMoreSales();
          break;
      }
    },

    getMoreLeads(){
      const id = this.leadsLastEvaluatedKey.Id;
      const created = this.leadsLastEvaluatedKey.Created;
      const companyId = this.leadsLastEvaluatedKey.CompanyId;
      this.$store.dispatch("getRequest","getMoreLeadsByCompany&query=" + [this.companyId, id, created, companyId]).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.leads = [...this.leads, ...body.Items];

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.leadsLastEvaluatedKey = body.LastEvaluatedKey;
          }else{
            this.leadsLastEvaluatedKey = null;
          }
        }
      });
    },

    getMoreClosedLeads(){
      const id = this.closedLeadsLastEvaluatedKey.Id;
      const created = this.closedLeadsLastEvaluatedKey.Created;
      const companyId = this.closedLeadsLastEvaluatedKey.CompanyId;
      this.$store.dispatch("getRequest","getMoreClosedLeadsByCompany&query=" + [this.companyId, id, created, companyId]).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.closedLeads = [...this.closedLeads, ...body.Items];

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.closedLeadsLastEvaluatedKey = body.LastEvaluatedKey;
          }else{
            this.closedLeadsLastEvaluatedKey = null;
          }
        }
      });
    },

    getMoreProjects(){
      const id = this.projectsLastEvaluatedKey.Id;
      const created = this.projectsLastEvaluatedKey.Created;
      const companyId = this.projectsLastEvaluatedKey.CompanyId;
      this.$store.dispatch("getRequest","getMoreProjectsByCompany&query=" + [this.companyId, id, created, companyId]).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.projects = [...this.projects, ...body.Items];

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.projectsLastEvaluatedKey = body.LastEvaluatedKey;
          }else{
            this.projectsLastEvaluatedKey = null;
          }
        }
      });
    },

    getMoreClosedProjects(){
      const id = this.closedProjectsLastEvaluatedKey.Id;
      const created = this.closedProjectsLastEvaluatedKey.Created;
      const companyId = this.closedProjectsLastEvaluatedKey.CompanyId;
      this.$store.dispatch("getRequest","getMoreClosedProjectsByCompany&query=" + [this.companyId, id, created, companyId]).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.closedProjects = [...this.closedProjects, ...body.Items];

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.closedProjectsLastEvaluatedKey = body.LastEvaluatedKey;
          }else{
            this.closedProjectsLastEvaluatedKey = null;
          }
        }
      });
    },

    getMoreSales(){
      const id = this.salesLastEvaluatedKey.Id;
      const salesDate = this.salesLastEvaluatedKey.SalesDate;
      const companyId = this.salesLastEvaluatedKey.CompanyId;
      this.$store.dispatch("getRequest","getMoreSalesByCompany&query=" + [this.companyId, id, salesDate, companyId]).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.sales = [...this.sales, ...body.Items];

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.salesLastEvaluatedKey = body.LastEvaluatedKey;
          }else{
            this.salesLastEvaluatedKey = null;
          }
        }
      });
    },

    getCompanyById(){
      this.$store.dispatch("getRequest","getCompanyById&query=" + this.companyId).then(resp => {
        if(resp && resp.statusCode === 200) {
          const body = JSON.parse(resp.body);
          this.$store.commit('setCompanyDashboard', body.company);
          this.$store.commit('setShouldReloadDashboard', false);
          this.contacts = body.persons.contacts;
          this.users = body.persons.users;

          this.setCompanyUsers(body.persons);
        }
      })
    },

    getData(){
      this.$store.dispatch("getRequest","getLeadsByCompany&query=" + this.companyId).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.leads = body.Items;

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.leadsLastEvaluatedKey = body.LastEvaluatedKey;
          }else{
            this.projectsLastEvaluatedKey = null;
          }
        }
      });

      this.$store.dispatch("getRequest","getProjectsByCompany&query=" + this.companyId).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.projects = body.Items;

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.projectsLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });

      this.$store.dispatch("getRequest","getSalesByCompany&query=" + this.companyId).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.sales = body.Items;

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.salesLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });
    },

    getClosedData(){
      this.$store.dispatch("getRequest","getClosedLeadsByCompany&query=" + this.companyId).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.closedLeads = body.Items;

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.closedLeadsLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });

      this.$store.dispatch("getRequest","getClosedProjectsByCompany&query=" + this.companyId).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.closedProjects = body.Items;

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.closedProjectsLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });
    },
  },
  mounted(){
    this.getCompanyById();
    this.getData();
    this.getClosedData();
  },
  watch:{
    //In case we switch routes by using the search function with the magnifying glass icon/button,
    //we need to set the new companyId and get the new company data.
    $route(to){
      if(to.params.Id){
        if(this.companyId !== to.params.Id){
          this.companyId = to.params.Id;
          this.getCompanyById();
        }
      }
    },

    '$store.getters.shouldReloadDashboard': {
      handler() {
        // Fetch the data again when the flag changes
        if(this.$store.getters.shouldReloadDashboard){
          this.getCompanyById();
          this.getData();
          this.getClosedData();
        } 
      },
    },

    dataWithCorrespondence:{
      handler(newVal) {
        // find the latest option based on the timestamp
        if (newVal.length > 0) {
          // adds the ProjectId, Type, Title & CreationNumber to all correspondences, so we can either:
          // route to the projectDashboard or show a toast in which lead the last contact happened.
          const correspondencesArrayWithProjectId = this.dataWithCorrespondence.map((entry) => {
            return {
              ...entry,
              Correspondence: entry.Correspondence.map((correspondenceEntry) => {
                return {
                  ...correspondenceEntry,
                  ProjectId: entry.Id,
                  ProjectType: entry.Type,
                  ProjectTitle:entry.Title,
                  CreationNumber:entry.CreationNumber
                };
              })
            };
          });

          // returns all correspondences from all entries (lead / project)
          const correspondenceValues = correspondencesArrayWithProjectId.map(entry => entry.Correspondence).flat();

          // returns all correspondences that are not of type 'Beitrag'
          let filteredCorrespondences = correspondenceValues.filter(correspondenceValue =>
              correspondenceValue.Type && correspondenceValue.Type !== 'Beitrag');

          // sets the total count of our reports.
          this.reportCount = filteredCorrespondences && filteredCorrespondences.length;

          const lastContact = correspondenceValues.slice().sort((a, b) => b.Unix - a.Unix)[0];

          // returns the lastContact
          this.lastContact ={
            'Text': lastContact.Contact ? lastContact.Contact.Name + ' (' +
                new Date(Number(lastContact.Unix)).toLocaleDateString() + ')' :
              this.$t('correspondenceWithoutContact') + ' (' +
                new Date(Number(lastContact.Unix)).toLocaleDateString() + ')',
            'ProjectId':lastContact.ProjectId,
            'Type':lastContact.ProjectType,
            'ProjectTitle':lastContact.ProjectTitle + ' (' + lastContact.CreationNumber + ')',
          };
        }
      },
      immediate: true  // Trigger the watcher immediately on component creation

    }
  },
  // Sets company dashboard back to null so when we open a different dashboard,
  // it won't show the previous data for 1-2 seconds.
  unmounted(){
    this.$store.commit('setCompanyDashboard',null);
  }
}
</script>

<style scoped>
:deep(.p-tabview .p-tabview-panels){
  padding-left:0;
  padding-right:0;
}
</style>