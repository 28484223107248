<template>
  <div style="min-height: 600px">
    <div class="col-lg-12 control-section">
      <div class="content-wrapper">
        <ejs-grid id="Grid" ref="grid" :dataSource="gridDataSource" width="100%" :allowPaging="true" :allowSelection="true"
                  :toolbar='toolbarOptions' :allowExcelExport='true' :toolbarClick='toolbarClick' :allowSorting="true"
                  :allowFiltering="true" :filterSettings="filterOptions" :pageSettings="pageSettings">
          <e-columns>
            <e-column field="Title" :headerText="$t('title')" type="string" />
            <e-column field="Company" :headerText="$t('company')" type="string" />
            <e-column field="DeliveryDate" :headerText="$t('deliveryDate')" type="date" format="dd.MM.yyyy" />
            <e-column field="Probability" :headerText="$t('probability')" type="number" />
            <e-column field="Status" headerText="Status" type="string" />
            <e-column field="SaleValue" :headerText="$t('budget')" type="number" />
            <e-column field="OfferValue" :headerText="$t('offerValue')" type="number" />
            <e-column field="BonusOption" :headerText="$t('bonusOption')" type="number" />
            <e-column field="OfferNumber" :headerText="$t('offerNumber')" type="string" />
          </e-columns>
        </ejs-grid>
      </div>
    </div>
  </div>
</template>

<script>
import QueryBuilderFunctions from "@/mixins/query-builder-functions/queryBuilderFunctions";
import {ColumnDirective, ColumnsDirective} from "@syncfusion/ej2-vue-querybuilder";
import {ExcelExport, Filter, GridComponent, Page, Selection, Sort, Toolbar} from "@syncfusion/ej2-vue-grids";

export default {
  name: "OfferQueryTable",
  mixins:[QueryBuilderFunctions],
  data() {
    return {
      filterOptions:{type:'Excel'},
      gridDataSource:[],
      pageSettings: { pageSize: 20, pageCount: 5 },
      importRules: {},
      toolbarOptions: ['ExcelExport']
    };
  },
  components: {
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective,
    'ejs-grid': GridComponent,
  },

  methods: {
    toolbarClick(args) {
      if (args.item.id === 'Grid_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        this.$refs.grid.excelExport();
      }
    },

    prepareDataSource(){
      let src = [];
      let offers = this.$store.getters.offers;

      for (let offer of offers){
        let obj = {};
        obj["Title"] = offer["Title"];
        obj["Company"] = offer["Company"].Name;
        obj["DeliveryDate"] = new Date(Number(offer["DeliveryDate"]));
        obj["Probability"] = Number(offer["Probability"]);
        obj["Status"] = this.getOfferStatus(offer["Status"]);
        obj["SaleValue"] = Number(offer["SaleValue"]);
        obj["OfferValue"] = Number(offer["OfferValue"]);
        obj["BonusOption"] = offer['BonusOption'] ? Number(offer["BonusOption"]) : 0;
        obj["OfferNumber"] = Number(offer["OfferNumber"]);

        src.push(obj);
      }
      return src;
    },

    getOfferStatus(offerStatus){
      switch (offerStatus){
        case '10':
          return this.$t('open');
        case '20':
          return this.$t('sold');
        case '30':
          return this.$t('rejected');
        case '40':
          return this.$t('replaced');
        case '50':
          return this.$t('lost');
        case '60':
          return this.$t('delayed');
      }
    },
       
    goToProjectDashboard(args){
      const projectId = args.rowData.ProjectId;
      this.$router.push({
        path:"/projects/dashboard/" + projectId,
        params:projectId
      });
    }
  },

  mounted(){
    this.gridDataSource = this.prepareDataSource(this.$store.getters.offers);
  },

  provide: {
    grid: [Page, Selection, Sort,Toolbar, ExcelExport, Filter]
  }
}
</script>

<style scoped>

</style>