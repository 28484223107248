<template>
  <div style="min-height: 600px">
    <div class="col-lg-12 control-section">
      <div class="content-wrapper">
        <ejs-grid id="Grid" ref="grid" :dataSource="gridDataSource" width="100%" :allowPaging="true" :allowSelection="true"
                  :toolbar='toolbarOptions' :allowExcelExport='true' :toolbarClick='toolbarClick' :allowSorting="true"
                  :allowFiltering="true" :filterSettings="filterOptions" :pageSettings="pageSettings"
                  @excelQueryCellInfo="onExcelQueryCellInfo">
          <e-columns>
            <e-column field="Title" :headerText="$t('title')" type="string" />
            <e-column field="Responsible" :headerText="$t('responsible')" type="string" />
            <e-column field="Company" :headerText="$t('company')" type="string" />
            <e-column field="Creator" :headerText="$t('creator')" type="string" />
            <e-column field="Description" :headerText="$t('description')" type="string" />
            <e-column field="Prio" :headerText="$t('prio')" type="string" />
            <e-column field="Classification" :headerText="$t('classification')" type="string" />
            <e-column field="LeadTags" headerText="Tags" type="string" />
            <e-column field="CreationDate" :headerText="$t('creationDate')" type="date" format="dd.MM.yyyy" />
          </e-columns>
        </ejs-grid>
      </div>
    </div>
  </div>
</template>

<script>
import {ColumnDirective, ColumnsDirective} from "@syncfusion/ej2-vue-querybuilder";
import {ExcelExport, GridComponent, Page, Selection, Sort, Toolbar, Filter} from "@syncfusion/ej2-vue-grids";
import QueryBuilderFunctions from "@/mixins/query-builder-functions/queryBuilderFunctions";

export default {
  name: "LeadQueryTable",
  mixins:[QueryBuilderFunctions],
  data() {
    return {
      filterOptions:{type:'Excel'},
      gridDataSource: [],
      pageSettings: { pageSize: 20, pageCount: 5 },
      importRules: {},
    };
  },
  components: {
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective,
    'ejs-grid': GridComponent,
  },

  computed:{
    toolbarOptions(){
      const options = ['ExcelExport'];

      if(this.lastEvaluatedKey){
        options.push(
          { text: 'Load all data', tooltipText: 'Load all data', prefixIcon: 'e-refresh', id: 'toolbar_load_all', align: 'Right'},
          { text: 'Load more data', tooltipText: 'Load more data', prefixIcon: 'e-refresh', id: 'toolbar_load', align: 'Right' });
      }
      return options;
    },
    
    lastEvaluatedKey(){
      return this.$store.getters.openLeadsLastEvaluatedKey;
    }
  },

  methods: {
    toolbarClick(args) {
      if (args.item.id === 'Grid_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        this.$refs.grid.excelExport();
      }
      
      if (args.item.id === 'toolbar_load') {
        this.getMoreLeads();
      }

      if(args.item.id === 'toolbar_load_all'){
        this.getAllLeads();
      }
    },

    getMoreLeads(){
      return new Promise((resolve) => {
        if(this.lastEvaluatedKey && this.lastEvaluatedKey.Created && this.lastEvaluatedKey.Id){
          const id = Number(this.lastEvaluatedKey["Id"]);
          const created = Number(this.lastEvaluatedKey["Created"]);
          this.$store.dispatch("getRequest","getMoreLeads&query=" +  [id, created]).then(resp => {
            if (resp && resp.statusCode === 200) {
              const body = JSON.parse(resp.body);
              this.$store.commit('setLeadsData', body.Items);

              if (body.LastEvaluatedKey && body.Items.length > 0) {
                this.$store.commit('setOpenLeadsLastEvaluatedKey', body.LastEvaluatedKey);
              }else{
                this.$store.commit('setOpenLeadsLastEvaluatedKey', null);
              }

              resolve();
            }
          });
        }else{
          this.$toast.add({ severity: 'error', summary: this.$t('Info'), detail: this.$t('swal.allDataReceived'), life: 5000 });
        }
      });
    },

    prepareDataSource(leads){
      const src = [];
      for (let lead of leads){
        let obj = {};
        obj["Title"] = lead["Title"];
        obj["Creator"] = lead["Creator"];
        obj["Description"] = lead["Description"];
        obj["Prio"] = lead["Prio"];

        // the table cannot display nested values so we modify / prepare the data.
        obj["Company"] = this.getValFromObject(lead.Company, "Name") + " " + this.getValFromObject(lead.Company, "CompanyNumber");
        obj["Responsible"] = this.getValFromObject(lead.Responsible, "Name");
        obj["Classification"] = this.getValFromObject(lead.LeadTagContainer, "ContainerName");

        obj["LeadTags"] = lead.LeadTags ? this.getTags(lead.LeadTags) : "";

        obj["CreationDate"] = new Date(parseInt(lead.Created));

        src.push(obj);
      }
      this.gridDataSource = [...this.gridDataSource, ...src];
    },

    getAllLeads(){
      this.getMoreLeads().then(()=>{
        if(this.lastEvaluatedKey) {
          this.getAllLeads();
        }
      });
    },

    /**
     * Changed the unix into a date for the Excel export.
     * @param args
     */
    onExcelQueryCellInfo(args) {
      if (args.column.field === 'CreationDate') {
        const date = new Date(args.value);
        args.value = date.toLocaleDateString();
      }
    },
  },
  mounted(){
    this.prepareDataSource(this.$store.getters.leads);
  },

  provide: {
    grid: [Page, Selection, Sort,Toolbar, ExcelExport, Filter]
  }
}
</script>

<style scoped>

</style>