<template>
  <div v-if="showCompleteHeader" class="flex justify-content-between">
    <div class="flex align-items-start flex-column md:flex-row">
      <Avatar class="mr-5 mb-3 lg:mb-0 text-4xl" style="height: 90px;width:90px; min-width: 90px" :label="person.FriendlyName.substring(0,2).toUpperCase()" shape="circle" />
      <div>
        <span class="text-900 font-medium text-3xl">{{person.FriendlyName}}</span>
        <div class="flex align-items-center flex-wrap text-sm">
          <div class="mr-5  mt-3">
            <span class="font-medium text-500">Position</span>
            <div>{{person.Customer}}</div>
          </div>
          <div class="mr-5  mt-3">
            <span class="font-medium text-500">{{$t('company')}}</span>
            <div class="text-blue-700 cursor-pointer" @click="goToCompanyDashboard">{{person.CompanyName}}</div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <PrimeButton v-if="personIsFavorite" icon="pi pi-star-fill" plain text @click="unfavorisePerson" />
      <PrimeButton v-else icon="pi pi-star" plain text @click="favorisePerson" />
      <PrimeButton icon="pi pi-angle-up" rounded @click="setShowCompleteHeader(false)" />
      <PrimeButton v-if="showDashboardViewer" icon="pi pi-angle-right" rounded @click="setShowDashboardViewer(false)" />
      <PrimeButton v-else icon="pi pi-angle-left" rounded @click="setShowDashboardViewer(true)" />
    </div>
  </div>

  <div v-else>
    <div class="flex justify-content-between flex-column md:flex-row">
      <div>
        <span class="text-900 font-medium text-3xl">{{person.FriendlyName}}</span>
      </div>
      <div>
        <PrimeButton v-if="personIsFavorite" icon="pi pi-star-fill" plain text @click="unfavorisePerson" />
        <PrimeButton v-else icon="pi pi-star" plain text @click="favorisePerson" />
        <PrimeButton icon="pi pi-angle-down" rounded @click="setShowCompleteHeader(true)" />
        <PrimeButton v-if="showDashboardViewer" icon="pi pi-angle-right" rounded @click="setShowDashboardViewer(false)" />
        <PrimeButton v-else icon="pi pi-angle-left" rounded @click="setShowDashboardViewer(true)" />
      </div>
    </div>
  </div>
</template>

<script>
import PrimeButton from "primevue/button";
import Avatar from "primevue/avatar";

export default {
  name: "PersonDashboardHeader",
  emits:['showCards', 'showDashboardViewer'],
  components:{PrimeButton, Avatar},
  data(){
    return{
      favorisedPersons:[],
      showCompleteHeader:true,
    }
  },
  props:{
    person:Object,
    showDashboardViewer:Boolean,
  },
  computed:{
    personIsFavorite(){
      if (localStorage.getItem("favorisedPersons") !== null) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.favorisedPersons = JSON.parse(localStorage.getItem("favorisedPersons"));
      }
      if(this.favorisedPersons.length > 0){
        for(let favorisedPerson of this.favorisedPersons){
          if(favorisedPerson.Id === this.person.User){
            return true;
          }
        }
      }
      return false;
    },

  },
  methods:{
    favorisePerson(){
      const person = {
        Id:this.person.User,
        FriendlyName:this.person.FriendlyName,
        CompanyName:this.person.CompanyName,
        Position:this.person.OptionalParams && this.person.OptionalParams.Position,
        Department:this.person.OptionalParams && this.person.OptionalParams.Department,
        Type:'User',
      }
      this.favorisedPersons.push(person);

      window.localStorage.setItem("favorisedPersons", JSON.stringify(this.favorisedPersons));
    },

    unfavorisePerson(){
      this.favorisedPersons = this.favorisedPersons.filter(person => person.Id !== this.person.User);

      window.localStorage.setItem("favorisedPersons", JSON.stringify(this.favorisedPersons));
    },

    setShowCompleteHeader(trueOrFalse){
      this.showCompleteHeader = trueOrFalse;
      this.$emit('show-cards',trueOrFalse);
    },

    setShowDashboardViewer(trueOrFalse){
      this.$emit('show-dashboard-viewer',trueOrFalse);
    },

    goToCompanyDashboard(){
      this.$router.push({
        path:"/companies/dashboard/" + this.person.CompanyId,
        params:this.person.CompanyId
      });
    }
  },
}
</script>

<style scoped>

</style>