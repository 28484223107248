<template>
  <!-- Using this styling to cancel the standardized border and background-color -->
  <Menubar class="flex overflow-x-auto bg-white border-0 font-bold text-xl" >
    <template #start>
      <div class="flex justify-content-between align-items-center gap-2">
        <UserAvatar class="mr-2" />
        <div class="flex justify-content-between align-items-center gap-2">
          <template v-for="item in items" :key="item">
            <a v-if="item.external"
               :href="item.to"
               class="menubar-item"
               target="_blank" >
              <div class="flex justify-content-between align-items-center gap-2 p-2">
                <i :class="item.icon"></i>
                <span>{{item.label}}</span>
              </div>
            </a>
            <router-link v-else
                         :to="item.to"
                         class="menubar-item"
                         :class="{ 'active-item': $route.path === item.to }">
              <div class="flex justify-content-between align-items-center gap-2 p-2">
                <i :class="item.icon"></i>
                <span>{{item.label}}</span>
              </div>
            </router-link>
          </template>
        </div>
      </div>
    </template>
    <template #end>
      <div class="flex overflow-x-auto">
        <PrimeButton class="mobile"
                     icon="pi pi-plus"
                     :label="this.$t('new')"
                     size="small"
                     @click="openCreateMenu" />
        <PrimeMenu :model="createMenuOptions"
                   ref="createMenu"
                   :popup="true"/>
        <PrimeButton v-if="isDesktop"
                     icon="pi pi-search"
                     text
                     class="ml-2"
                     @click="openSearchbar"/>
        <PrimeButton v-if="isDesktop"
                     icon="pi pi-bell"
                     text
                     class="ml-2"
                     :badge="notifications.length"
                     badge-severity="danger"
                     @click="openNotifications"/>
        <PrimeButton icon="pi pi-language"
                     text class="ml-2"
                     @click="changeLanguage"/>
        <PrimeButton icon="pi pi-sign-out"
                     text class="ml-2"
                     @click="logout" />
      </div>
    </template>
  </Menubar>

  <Popup :header="this.$t('createLead')"
         :modal="isDesktop"
         :visible="creatingLead"
         styling="width:90%"
         :show-save-button="true"
         @button-clicked="submitAddLeadPopup"
         @close-popup="creatingLead = false">
    <AddLead ref="addLead" @close-popup="creatingLead = false"/>
  </Popup>

  <Popup :maximizable="true"
         :header="this.$t('createProject')"
         :modal="true"
         :visible="creatingProject"
         styling="width:50%"
         :show-save-button="true"
         @button-clicked="submitAddProjectPopup"
         @close-popup="creatingProject = false">
    <AddProject ref="addProject"
                @close-popup="creatingProject = false" />
  </Popup>

  <Popup :maximizable="true"
         :header="this.$t('selectCorrespondenceType')"
         :modal="true"
         :visible="selectingCorrespondenceType"
         :show-save-button="true"
         @button-clicked="submitSelectCorrespondencePopup"
         @close-popup="selectingCorrespondenceType = false"
         styling="width:50%;max-height:70%" >
    <SelectCorrespondenceType ref="submitCorrespondence"
                              @submit-select-correspondence="setCorrespondenceType" />
  </Popup>

  <Popup :maximizable="true"
         :header="this.$t('createCorrespondence')"
         :modal="true" :visible="creatingCorrespondence"
         :show-save-button="true"
         @button-clicked="submitAddCorrespondencePopup"
         @close-popup="creatingCorrespondence = false"
         styling="width:50%;max-height:70%" >
    <AddCorrespondence ref="addCorrespondence"
                       :correspondence-type="correspondenceType"
                       @close-popup="creatingCorrespondence = false" />
  </Popup>

  <Popup :header="this.$t('createReport')"
         :modal="true"
         :visible="creatingVisitReport"
         :show-menu-button="true"
         :menu-options="visitReportMenuOptions"
         styling="width:90%"
         :show-save-button="true"
         @button-clicked="submitVisitReportPopup"
         @close-popup="creatingVisitReport = false" >
    <AddVisitReport ref="addVisitReport"
                    @close-popup="creatingVisitReport = false"
                    @close-add-visit-report="creatingVisitReport = false" />
  </Popup>

  <Popup :modal="true"
         :header="$t('search')"
         :position="'top'"
         :visible="searchbarIsOpen"
         @close-popup="searchbarIsOpen = false"
         styling="width:90%">
    <NavbarSearchbar @close-popup="searchbarIsOpen = false"
                     @edit-lead="openEditLead" />
  </Popup>

  <Popup :modal="isDesktop"
         :visible="editingLead"
         :header="this.$t('editLeads')"
         styling="width:90%"
         :show-save-button="showSaveButton"
         @button-clicked="submitEditLeadPopup"
         @close-popup="editingLead = false"
         :show-inline-message="true"
         :inline-message="inlineMessage">
    <EditLead ref="editLead"
              :lead-data-prop="selectedLead"
              :disabled="selectedLead.Status === '20'"
              :show-reminder="showReminders"
              @close-reminders="showReminders = false"
              @submit-edit-lead="submitLead"/>
  </Popup>

  <Popup :modal="true"
         :visible="creatingContact"
         :header="this.$t('createContact')"
         :styling="isDesktop ? 'width:50%' : 'width:90%'"
         :show-save-button="true"
         @button-clicked="submitAddContactPopup"
         @close-popup="creatingContact = false" >
    <AddContact ref="addContact"
                @close-popup="creatingContact = false" />
  </Popup>

  <Popup :modal="true"
         :visible="showNotifications"
         :header="this.$t('notifications')"
         styling="width:40%"
         @close-popup="showNotifications = false" >
    <Notification @close-popup="showNotifications = false" />
  </Popup>

</template>

<script>
import AddLead from "@/components/leads/add-lead/AddLead";
import EditLead from "@/components/leads/edit-lead/EditLead";
import Popup from "@/components/global-components/popup/Popup";
import AddProject from "@/components/projects/add-project/AddProject";
import Menubar from 'primevue/menubar';
import PrimeButton from 'primevue/button';
import PrimeMenu from "primevue/menu";
import NavbarSearchbar from "@/components/global-components/navbar/navbar-searchbar/NavbarSearchbar";
import {Auth} from "aws-amplify";
import UserAvatar from "@/components/global-components/user-avatar/UserAvatar";
import AddCorrespondence from "@/components/correspondence/add-correspondence/AddCorrespondence";
import AddVisitReport from "@/components/reports/visit-report/add-visit-report/AddVisitReport";
import globalComputedProperties from "@/mixins/global-computed-properties/global-computed-properties";
import AddContact from "@/components/contact/add-contact/AddContact";
import Notification from "@/components/global-components/navbar/notification/Notification";
import SelectCorrespondenceType
  from "@/components/global-components/navbar/select-correspondence-type/SelectCorrespondenceType";

export default {
  name: "NavbarComponent",
  components:{
    SelectCorrespondenceType,
    Notification,
    AddContact,
    AddCorrespondence,
    UserAvatar,
    NavbarSearchbar,
    Popup, AddLead, EditLead, AddProject, AddVisitReport, Menubar, PrimeButton, PrimeMenu
  },
  mixins:[globalComputedProperties],
  data(){
    return{
      isMenuOpen: false,
      creatingContact:false,
      creatingCorrespondence:false,
      creatingLead:false,
      creatingProject:false,
      creatingVisitReport:false,
      showNotifications:false,
      searchbarIsOpen:false,
      editingLead:false,
      selectedLead:null,
      showReminders:false,
      editLeadMenuOptions: [],
      showSaveButton:false,
      inlineMessage:"",
      visitReportMenuOptions:[],
      selectingCorrespondenceType:false,
      correspondenceType:"",
    }
  },
  props:{
    navbarHeader:String,
  },

  computed:{
    items(){
      let menuItems = []
      if(window.innerWidth < 600){
        menuItems = [{
          label: this.$t('leads'),
          icon: 'text-cyan-500 pi pi-fw pi-pencil',
          to:"/leads",
          external: false,
        }];
      }else{
        menuItems = [
          {
            label: this.$t('leads'),
            icon: 'text-cyan-500 pi pi-fw pi-pencil',
            to:"/leads",
            external: false,
          },
          {
            label: this.$t('projects'),
            icon: 'text-blue-500 pi pi-fw pi-briefcase',
            to:"/projects",
            external: false,
          },
          {
            label: this.$t('sales'),
            icon: 'text-indigo-500 pi pi-fw pi-dollar',
            to: '/sales',
            external: false,
          },
          {
            label:this.$t('companies'),
            icon: 'text-bluegray-500 pi pi-building',
            to: '/companies',
            external: false,
          },
          {
            label:this.$t('persons'),
            icon: 'text-indigo-300 pi pi-user',
            to: '/persons',
            external: false,
          },
          {
            label:this.$t('analysis'),
            icon: 'text-purple-300 pi pi-database',
            to: '/queryBuilder',
            external: false,
          }
        ];
        if(this.isAdmin){
          menuItems.push({
            label:this.$t('admin'),
            icon: 'text-black-300 pi pi-shield',
            to: 'https://admin.4service.io',
            external: true,
          })
        }
      }
      return menuItems
    },

    createMenuOptions(){
      if(window.innerWidth < 600){
        return [
          { label: this.$t('newLead'), icon: 'pi pi-fw pi-plus',
            command: () => { this.createNewLead();}
          },
          { separator : true},
          { label: this.$t('newContact'), icon: 'pi pi-fw pi-user',
            command: () => {this.createNewContact();}
          },
        ]
      }else{
        return [
          { label: this.$t('newLead'), icon: 'pi pi-fw pi-inbox',
            command: () => { this.createNewLead();}
          },
          { separator : true},
          { label: this.$t('newProject'), icon: 'pi pi-fw pi-briefcase',
            command: () => {this.createNewProject();}
          },
          { separator : true},
          { label: this.$t('newContact'), icon: 'pi pi-fw pi-user',
            command: () => {this.createNewContact();}
          },
          { separator : true},
          {
            label: this.$t('newCorrespondence'), icon: 'pi pi-fw pi-copy',
            command: () => {
              this.createNewCorrespondence();}
          },
          {separator: true},
          {
            label: this.$t('newReport'), icon: 'pi pi-fw pi-folder',
            command: () => { this.createNewVisitReport();}
          },
        ]
      }
    },

    isAdmin(){
      return this.$store.getters.userType.toLowerCase() === "admin";
    },

    notifications(){
      return this.$store.getters.notifications;
    }
  },

  methods:{
    openCreateMenu(event){
      this.$refs.createMenu.toggle(event);
    },

    openNotifications(){
      this.showNotifications = true;
    },

    createNewLead(){
      this.creatingLead = true;
    },

    createNewProject(){
      this.creatingProject = true;
    },

    //A correspondence is simply a rich text editor component to which we can
    // attach files and link them to a person or a company. If it is linked to a
    // person it is linked to a company directly.
    createNewCorrespondence(){
      this.selectingCorrespondenceType = true;
    },

    createNewVisitReport(){
      this.createVisitReportMenuOptions();
      this.creatingVisitReport = true;
    },

    submitVisitReportPopup(){
      this.$refs.addVisitReport.saveButton();
    },

    createVisitReportMenuOptions(){
      const optionsMenu = []
        optionsMenu.push(
          { label: this.$t('addTask'), icon: 'pi pi-fw pi-file',
            command: () => { this.$refs.addVisitReport.setOpenAddTask();}
          },
          { separator : true},
          { label: this.$t('editTask'), icon: 'pi pi-fw pi-file',
            command: () => {this.$refs.addVisitReport.setOpenEditTask();}
          },
          { separator : true},
          { label: this.$t('pdfExport'), icon: 'pi pi-fw pi-check',
            command: () => {this.$refs.addVisitReport.pdfExport();}
          },
        );

      this.visitReportMenuOptions = optionsMenu;
    },

    createNewContact(){
      this.creatingContact = true;
    },

    openSearchbar(){
      this.searchbarIsOpen = !this.searchbarIsOpen;
    },

    changeLanguage(){
      this.$i18n.locale === "en" ? this.setLanguage('de') : this.setLanguage('en');
    },

    setLanguage(language){
      localStorage.setItem("lang", language);
      this.$i18n.locale = language;
      this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.changingLanguage'), life: 3000 });
      location.reload();
    },

    logout(){
      this.$confirm.require({
        header: this.$t('swal.logoutHeader'),
        message: this.$t('swal.logoutText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          try {
            Auth.signOut();
            this.$store.dispatch('signout');
          } catch (error) {
            alert(error.message);
          }
          this.$router.push({
            path:'/login',
            name: 'Login',
          })
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });

    },

    openEditLead(data){
      this.showSaveButton = data.Status === '10';
      this.inlineMessage = this.$t('openSince') + ' ' + this.getDueDate(data.Created) + ' ' +this.$t('days');
      this.editingLead = true;
      this.selectedLead = data;
    },

    setCorrespondenceType(correspondenceType){
      this.correspondenceType = correspondenceType;
      this.creatingCorrespondence = true;
      this.selectingCorrespondenceType = false;
    },

    submitAddLeadPopup(){
      this.$refs.addLead.checkIfFilesNotUploaded();
    },

    submitEditLeadPopup(){
      this.$refs.editLead.checkIfFilesNotUploaded();
    },

    submitAddProjectPopup(){
      this.$refs.addProject.addProject();
    },

    submitAddCorrespondencePopup(){
      this.$refs.addCorrespondence.checkIfFilesNotUploaded();
    },

    submitAddContactPopup(){
      this.$refs.addContact.addContact();
    },

    submitSelectCorrespondencePopup(){
      this.$refs.submitCorrespondence.selectCorrespondence();
    },

    closeEditLead(){
      this.editingLead = false;
      this.selectedLead = null;
    },

    /**
     * Dispatches editLead to store and closes the popup while emptying the selectedLead as well.
     * @param editedLeadData
     */
    submitLead(editedLeadData){
      editedLeadData.LastEdited = new Date().getTime();
      editedLeadData.Phase = {Number:"10",Name:'Projektanfrage'};
      this.$store.dispatch('editLead',editedLeadData).then(() => {
        this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('swal.leadEdited'), life: 3000 });
        if(this.$store.getters.companyDashboard && 
          this.$router.currentRoute._value.name === 'Company Dashboard' &&
          this.$router.currentRoute._value.params.Id === editedLeadData.Company.Id) {
            this.$store.commit('setShouldReloadDashboard', true);
          }
        this.editingLead = false;
        this.selectedLead = {};
      });
    },

    reopenLead(selectedLead){
      this.$confirm.require({
        header: this.$t('swal.reopenLeadHeader'),
        message: this.$t('swal.reopenLeadText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          selectedLead.Status = "10";
          this.$store.dispatch('reopenLead',selectedLead).then(() => {
            this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.leadReopened'), life: 3000 });
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    getDueDate(unix){
      const nowUnix = new Date().getTime() / 1000;
      const timeStamp = unix / 1000;

      return (Math.round((nowUnix - timeStamp) / 60 / 60 / 24))
    },
  },
}
</script>

<style scoped>

.active-item {
  background-color: #f0f0f0;
  border-radius:5px;
  border: 2px solid #3B82F6;
}

.menubar-item {
  color: inherit !important;
  text-decoration: none; /* Remove underlining from the item label */
}

.menubar-item i {
  text-decoration: none; /* Remove underlining from the icon */
}

@media screen and (max-width: 600px) {
  .mobile{
    position:fixed;
    bottom:20px;
    right:20px;
    z-index:10;
  }
}

</style>