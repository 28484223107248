<template>
  <div style="min-height: 600px">
    <div class="col-lg-12 control-section">
      <div class="content-wrapper">
        <ejs-grid id="Grid"
                  ref="grid"
                  :dataSource="gridDataSource"
                  width="100%"
                  :allowPaging="true"
                  :allowSelection="true"
                  :toolbar='toolbarOptions'
                  :allowExcelExport='true'
                  :toolbarClick='toolbarClick'
                  :allowSorting="true"
                  :allowFiltering="true"
                  :filterSettings="filterOptions"
                  :pageSettings="pageSettings"
                  @recordClick="goToCompanyDashboard">
          <e-columns>
            <e-column field="Name" :headerText="$t('name')" type="string" />
            <e-column field="City" :headerText="$t('city')" type="string" />
            <e-column field="Address" :headerText="$t('address')" type="string" />
            <e-column field="Phone" :headerText="$t('phoneNumber')" type="string" />
            <e-column field="Responsible" :headerText="$t('responsible')" type="string" />
          </e-columns>
        </ejs-grid>
      </div>
    </div>
  </div>
</template>

<script>
import {ColumnDirective, ColumnsDirective} from "@syncfusion/ej2-vue-querybuilder";
import {ExcelExport, Filter, GridComponent, Page, Selection, Sort, Toolbar} from "@syncfusion/ej2-vue-grids";

export default {
  name: "CompanyQueryTable",
  data() {
    return {
      filterOptions:{type:'Excel'},
      pageSettings: { pageSize: 20, pageCount: 5 },
      importRules: {},
    };
  },
  components: {
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective,
    'ejs-grid': GridComponent,
  },
  computed:{
    toolbarOptions(){
      const options = ['ExcelExport'];

      if(this.lastEvaluatedKey){
        options.push(
          { text: 'Load all data', tooltipText: 'Load all data', prefixIcon: 'e-refresh', id: 'toolbar_load_all', align: 'Right'},
          { text: 'Load more data', tooltipText: 'Load more data', prefixIcon: 'e-refresh', id: 'toolbar_load', align: 'Right' });
      }
      return options;
    },

    gridDataSource(){
      return this.$store.getters.companyQueryData;
    },

    lastEvaluatedKey(){
      return this.$store.getters.companyLastEvaluatedKey;
    }
  },
  methods: {
    toolbarClick(args) {
      if (args.item.id === 'Grid_excelexport') {
        this.$refs.grid.excelExport();
      }

      if (args.item.id === 'toolbar_load') {
        this.getMoreCompanies().then((data) => {
          this.$store.commit('setCompanyQueryData', data);
        });
      }

      if(args.item.id === 'toolbar_load_all'){
        this.getAllCompanies();
      }
    },

    // Here we get the equipment data from the server and return it
    getCompaniesData(){
      if(this.gridDataSource.length === 0){
        this.$store.dispatch('getRequest',"getAdminCompanies").then((data)=>{
          if(data && data.statusCode && data.statusCode === 200){
            const body = JSON.parse(data.body)
            this.$store.commit('setCompanyLastEvaluatedKey', body["LastEvaluatedKey"]);
            this.$store.commit('setCompanyQueryData', body.Items);
          }
        });
      }
    },

    getMoreCompanies(){
      return new Promise((resolve) => {
        this.$store.dispatch('getRequest',"getMoreAdminCompanies&query=" + this.lastEvaluatedKey["Id"]).then((data)=>{
          const oldData = this.gridDataSource;
          const body = JSON.parse(data.body);
          this.$store.commit('setCompanyLastEvaluatedKey', body["LastEvaluatedKey"]);
          resolve(oldData.concat(body["Items"]));
        });
      })
    },

    getAllCompanies(){
      this.getMoreCompanies().then((data)=>{
        this.$store.commit('setCompanyQueryData', data);
        if(this.lastEvaluatedKey) {
          this.getAllCompanies();
        }
      });
    },
    
    goToCompanyDashboard(args){
      const companyId = args.rowData.Id;
      this.$router.push({
        path:"/companies/dashboard/" + companyId,
        params:companyId
      });
    }
  },
  provide: {
    grid: [Page, Selection, Sort,Toolbar, ExcelExport, Filter]
  },

  mounted(){
    this.getCompaniesData();
  },
}
</script>

<style scoped>

</style>