<template>
  <div class="col-12">
    <TabView :activeIndex="activeIndex">
      <TabPanel :header="this.$t('leads') + ' (' + totalLeadsLength + ')'">
        <CompanyMaterialTableLeadTab :leads="leads"
                                     :closed-leads="closedLeads"
                                     :show-load-more-leads="showLoadMoreLeads"
                                     :show-load-more-closed-leads="showLoadMoreClosedLeads"
                                     @lead-clicked="emitRowClicked"
                                     @load-more-data="emitLoadMoreData"/>
      </TabPanel>

      <TabPanel :header="this.$t('projects') + ' (' + totalProjectsLength + ')'">
        <CompanyMaterialTableProjectTab :projects="projects"
                                        :closedProjects="closedProjects"
                                        :show-load-more-projects="showLoadMoreProjects"
                                        :show-load-more-closed-projects="showLoadMoreClosedProjects"
                                        @load-more-data="emitLoadMoreData"/>
      </TabPanel>

      <TabPanel :header="this.$t('tasks') + ' (' + tasksLength + ')'">
        <CompanyMaterialTableTaskTab :company-id="company.Id"
                                     @task-clicked="emitRowClicked" />
      </TabPanel>

      <TabPanel :header="this.$t('offers') + ' (' + offersLength + ')'">
        <CompanyMaterialTableOfferTab :company-id="company.Id"
                                      @offer-clicked="emitRowClicked" />
      </TabPanel>

      <TabPanel :header="this.$t('sales') + ' (' + sales.length + ')'">
        <CompanyMaterialTableSaleTab :sales="sales"
                                     :show-load-more-sales="showLoadMoreSales"
                                     @sale-clicked="emitRowClicked"
                                     @load-more-data="emitLoadMoreData" />
      </TabPanel>

      <TabPanel :header="$t('equipments') + ' (' + companyEquipments.length + ')'" >
        <CompanyMaterialTableEquipmentTable :company-equipments="companyEquipments" />
      </TabPanel>

      <TabPanel :header="$t('employee') + ' (' + companyUsers.length + ')'">
        <CompanyMaterialTableUserTab :company-users="companyUsers" />
      </TabPanel>

      <TabPanel :header="this.$t('locations') + ' (' + companyLocations.length + ')'">
        <CompanyMaterialTableLocationTab :company-locations-prop="companyLocations" />
      </TabPanel>

      <TabPanel :header="this.$t('correspondence') + ' (' + correspondences.length + ')'">
        <CompanyMaterialTableCorrespondenceTab :company="company"
                                               :correspondences="correspondences"
                                               @correspondence-clicked="emitRowClicked" />
      </TabPanel>

      <TabPanel :header="this.$t('reports') + ' (' + reportCount + ')'">
        <CompanyMaterialTableReportTab :data-with-correspondence="dataWithCorrespondence" />
      </TabPanel>

    </TabView>
  </div>
</template>

<script>
import CompanyMaterialTableLeadTab from "@/components/company/company-material-table/company-material-table-lead-tab/CompanyMaterialTableLeadTab";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import CompanyMaterialTableProjectTab from "@/components/company/company-material-table/company-material-table-project-tab/CompanyMaterialTableProjectTab";
import CompanyMaterialTableOfferTab
  from "@/components/company/company-material-table/company-material-table-offer-tab/CompanyMaterialTableOfferTab";
import CompanyMaterialTableTaskTab
  from "@/components/company/company-material-table/company-material-table-task-tab/CompanyMaterialTableTaskTab";
import CompanyMaterialTableSaleTab
  from "@/components/company/company-material-table/company-material-table-sale-tab/CompanyMaterialTableSaleTab";
import CompanyMaterialTableLocationTab
  from "@/components/company/company-material-table/company-material-table-location-tab/CompanyMaterialTableLocationTab";
import CompanyMaterialTableUserTab
  from "@/components/company/company-material-table/company-material-table-user-tab/CompanyMaterialTableUserTab";
import CompanyMaterialTableCorrespondenceTab
  from "@/components/company/company-material-table/company-material-table-correspondence-tab/CompanyMaterialTableCorrespondenceTab";
import CompanyMaterialTableReportTab
  from "@/components/company/company-material-table/company-material-table-report-tab/CompanyMaterialTableReportTab";
import CompanyMaterialTableEquipmentTable
  from "@/components/company/company-material-table/company-material-table-equipment-tab/CompanyMaterialTableEquipmentTable";

export default {
  name: "CompanyMaterialTable",
  emits:['goToTableTab', 'rowClicked'],
  components: {
    CompanyMaterialTableEquipmentTable,
    CompanyMaterialTableReportTab,
    CompanyMaterialTableUserTab,
    CompanyMaterialTableLocationTab,
    CompanyMaterialTableSaleTab,
    CompanyMaterialTableCorrespondenceTab,
    CompanyMaterialTableTaskTab,
    CompanyMaterialTableOfferTab,
    CompanyMaterialTableProjectTab, CompanyMaterialTableLeadTab, TabView, TabPanel},
  props:{
    company:Object,
    activeIndex:Number,
    companyLocations:Array,
    companyUsers:Array,
    correspondences:Array,
    companyEquipments:Array,
    dataWithCorrespondence:Array,
    reportCount:String,
    leads:Array,
    projects:Array,
    closedLeads:Array,
    closedProjects:Array,
    sales:Array,
    showLoadMoreLeads:Object,
    showLoadMoreProjects:Object,
    showLoadMoreClosedLeads:Object,
    showLoadMoreClosedProjects:Object,
    showLoadMoreSales:Object,
  },

  computed:{
    totalProjectsLength(){
      return this.projects.length + this.closedProjects.length;
    },

    totalLeadsLength(){
      return this.leads.length + this.closedLeads.length;
    },

    tasksLength(){
      let taskLength = 0;
      const tasks = this.$store.getters.tasks;
      for(let project of this.$store.getters.projects){
        if(project.Company.Id === this.company.Id){
          for(let task of tasks){
              if(task.ProjectId === project.Id) taskLength++;
          }
        }
      }
      return taskLength
    },

    offersLength(){
      let offerLength = 0;
      for(let offer of this.$store.getters.offers){
          if(offer.Company.Id === this.company.Id) offerLength++;
        }

      return offerLength
    },
  },

  methods:{
    emitActiveIndex(){
      this.$emit('go-to-table-tab',0)
    },

    emitRowClicked(data){
      this.$emit('row-clicked',data);
    },

    emitLoadMoreData(param){
      this.$emit('load-more-data',param);
    }
  }
}
</script>

<style scoped>

</style>